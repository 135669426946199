import React, { useContext, useEffect, useState } from "react";
import "./Clientes.css"; // Import the component-specific styling
import ClienteInfo from "./ClienteInfo"; // Import the ClienteInfo component
import { Header } from "../Header/Header";
import { useForm } from "react-hook-form";
import { api } from "../../services/api";
import { useNavigate } from "react-router-dom";
import { ClientesFormSchema } from "./ClientesFormSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { SlPeople } from "react-icons/sl";
import { MdSearch } from "react-icons/md";
import { ClientsContext } from "../../providers/ClientesContext";

const Clientes = () => {
  

  const [loading, setLoading] = useState(false);
  const [isAddingCliente, setIsAddingCliente] = useState(false);
  const navigate = useNavigate();
  const [clientesComModais, setClientesComModais] = useState({});
  const [clienteDeleteId, setClienteDeleteId] = useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [searchClient, setSearchClient] = useState("");
  const [filteredClients, setFilteredClients] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(ClientesFormSchema),
  });

  
  const { clientes, setClientes } = useContext(ClientsContext);




 

  const confirmRemoveCliente = (clienteId) => {
    setClientesComModais({ ...clientesComModais, [clienteId]: true });
  
    setClienteDeleteId(clienteId);
    setShowDeleteConfirmation(true);
  };

  const deleteCliente = async () => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      await api.delete(`/clientes/${clienteDeleteId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const newClientesList = clientes.filter(
        (cliente) => cliente.id !== clienteDeleteId
      );
      setClientes(newClientesList);
      toast.success("Cliente excluído com sucesso");
      setShowDeleteConfirmation(false);
    } catch (error) {
      console.log(clienteDeleteId);
      console.log(error);
      toast.error("Ops alguma coisa deu errado!");
      setShowDeleteConfirmation(false);
    }
  };

  const addCliente = async (formData) => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const {data} = await api.post("/clientes", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
       setClientes([...clientes, data]);
       setIsAddingCliente(false);
       toast.success("Cliente adicionado com sucesso!");
      }
     
    catch (error) {
      console.log(error);
      const { data } = error.response;
      console.log(error);
      toast.error(`Erro: ${data.errors}`);
    }
  };

  const submit = (formData) => {
    setIsAddingCliente(false);
    reset({ nome: "", username: "", password: "", ip: "" });
    addCliente(formData);
  };

  useEffect(() => {
    const getAllClientes = async () => {
      const token = JSON.parse(localStorage.getItem("token"));
      setLoading(true);
      try {
        const { data } = await api.get("/clientes",  {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const clientesRevertidos = data.reverse();
        setClientes(clientesRevertidos);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          // Se o erro for 403 (Forbidden)      
          navigate('/login')
        } else {
          // Se for um erro diferente de 403, faça o log do erro
          console.log(error);
        } } finally {
          setLoading(false);
    };}

    getAllClientes();
  }, []);

  const submitSearch = (data) => {
    reset({ searchClient: "" });
    setSearchClient(searchClient);
  };

  useEffect(() => {
    const filteredClientsList = clientes.filter((cliente) =>
      cliente.nome.toLowerCase().includes(searchClient.toLowerCase()) || 
      cliente.cpf?.includes(searchClient) || cliente.cnpj?.includes(searchClient)
    );
    setFilteredClients(filteredClientsList);
  }, [searchClient, setFilteredClients, clientes, setClientes]);

  const clientesList = searchClient ? filteredClients : clientes;

  return (
    <>
      <Header />
      <div className="flexBox">
        <div
          className={isAddingCliente ? "containerBox" : "clientContainerBox"}
        >
          {isAddingCliente ? (
            <div>
              <form onSubmit={handleSubmit(submit)}>
                <h2>Clientes</h2>
                <input
                  type="text"
                  placeholder="Nome do Cliente"
                  {...register("nome")}
                />
                {errors.nome ? <p>{errors.nome.message}</p> : null}
                <input
                  type="text"
                  placeholder="Username do Cliente"
                  {...register("username")}
                />
                 <input
                  type="text"
                  placeholder="E-mail do Cliente"
                  {...register("email")}
                />
                {errors.username ? <p>{errors.username.message}</p> : null}
                <input
                  type="text"
                  placeholder="Senha do Cliente"
                  {...register("password")}
                />
                {errors.password ? <p>{errors.password.message}</p> : null}
                <input
                  type="text"
                  placeholder="CPF do Cliente"
                  {...register("cpf")}
                />
                {errors.cpf ? <p>{errors.cpf.message}</p> : null}
                <input
                  type="text"
                  placeholder="CNPJ do Cliente"
                  {...register("cnpj")}
                />
                {errors.cnpj ? <p>{errors.cnpj.message}</p> : null}
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>

                <button className="custom-button button" type="submit">
                  Cadastrar
                </button>
                <button
                  className="cancel-button-cliente"
                  onClick={() => setIsAddingCliente(false)}
                >
                  Cancelar
                </button>
                </div>
              </form>
            </div>
          ) : (
            <div className="botaoBox">
              <div className="titlePag">
                <SlPeople size={40} />
                <h2>Clientes</h2>
              </div>
              <div className="searchBoxFlex">
                <button
                  className="botao-add"
                  onClick={() => setIsAddingCliente(true)}
                >
                  Adicionar Cliente
                </button>
                <form
                  onSubmit={handleSubmit(submitSearch)}
                  className="formSearch"
                >
                  <input
                    type="text"
                    {...register("searchClient")}
                    value={searchClient}
                    placeholder="Digite o nome do cliente ou CPF/CNPJ"
                    onChange={(e) => setSearchClient(e.target.value)}
                  />
                  <button type="submit" className="btnSearch">
                    <MdSearch size={21} />
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>

        {loading ? (
          <>
            <div className=" container ">
              <p
                style={{
                  position: "relative",
                  justifyContent: "center",
                  color: "white",
                  margin: "30px",
                  padding: "34px",
                }}
              >
                Carregando...
              </p>
            </div>
          </>
        ) : (
<>
        <div className="container">
          {clientesList.length === 0 ? (
            <p
              style={{
                position: "relative",
                justifyContent: "center",
                color: "white",
                margin: "30px",
                padding: "34px",
              }}
            >
              Nenhum resultado encontrado.
            </p>
          ) : (
            <ul className="listas">
              {clientesList.map((cliente, index) => (
                <ClienteInfo
                  key={index}
                  clientesComModais={clientesComModais}
                  setShowDeleteConfirmation={setShowDeleteConfirmation}
                  showDeleteConfirmation={showDeleteConfirmation}
                  cliente={cliente}
                  deleteCliente={deleteCliente}
                  setClientesComModais={setClientesComModais}
                  confirmRemoveCliente={confirmRemoveCliente}
                  />
                  ))}
                </ul>
              )}
            </div>
            </>
            )}
          </div>
      
        </>
      );
    };
export default Clientes;