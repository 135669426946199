import { Link } from "react-router-dom";

export const SensorUserCardAdmin = ({ sensor }) => {
  return (
    <>
      <li key={sensor.id} className="itemCard">
        <div
       className="itemCardSensor"
        >
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center"  }}>
            <p className="h3-user"> Nome módulo: <strong> {sensor.nome}</strong></p>
            <p className="p-user">Id: <strong>{sensor.id}</strong></p>
          </div>
          <p className="p-user" style={{ width: "33%"}}>Tanque: <strong>{sensor.tanque?.nome}</strong></p>

          {sensor.ultimaLeitura ? (
            <>
              <div style={{ display: "flex", gap: "50px", alignItems: "center"}}>
                <p className="p-user">
                  Última leitura: <strong>{sensor.ultimaLeitura.data}</strong>
                </p>
                <p className="p-user">
                  Altura: <strong>{Number(sensor.ultimaLeitura.alturaCorrigida)} mm</strong>
                </p>
              </div>
            </>
          ) : (
            <p>Nenhuma leitura cadastrada.</p>
          )}
          
          <Link to={`/modulos/${sensor.id}`} className="botao btn">
            Visualizar leituras
          </Link>
        </div>
      </li>
    </>
  );
};
