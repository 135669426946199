import { React, useContext } from "react";
import "../../Header/Header.css";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../AuthContext";

export const HeaderInicioUser = () => {
    const {
        setUser,
        setUsername,
        setPassword,
        setUserRole,
      } = useContext(AuthContext);
    
      const navigate = useNavigate();

    const handleLogout = () => {
        setUser({});
        setUsername("");
        setPassword("");
        setUserRole("");
        localStorage.clear();
        navigate("/login");
      };

  return (
    <>
      <div className="headerBox">
        <div className="containerUser">
          {/* <div className="navDesktop"> */}
            {/* <nav className="navBar"> */}
             
              <div className="icons">
              <button className="btn-sair" onClick={handleLogout}>
            Sair
          </button>
              </div>
            {/* </nav> */}
          </div>
        </div>
      {/* </div> */}
      
    </>
  );
};
