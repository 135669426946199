import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { api } from "../../services/api";
import { AuthContext } from "../AuthContext";
import { Header } from "../Header/Header";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Footer } from "../Footer/Footer";
import { toast } from "react-toastify";
import { TanqueContext } from "../../providers/TanquesContext";
import { SensoresContext } from "../../providers/SensoresContext";
import { TanquesRetangularFormSchema } from "../Tanques/ValidacoesZod/TanquesRetangularFormSchema";

export const PagEditarCadaTanqueRetangular = () => {
  const [unicoTanque, setUnicoTanque] = useState(null);
  const { sensores, setSensores } = useContext(SensoresContext);
  const { idParam } = useParams();
  const { tanques, setTanques } = useContext(TanqueContext);
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    resolver: zodResolver(TanquesRetangularFormSchema),
  });
  const navigate = useNavigate();

  useEffect(() => {
    const loadTanque = async () => {
    const token = JSON.parse(localStorage.getItem("token"));

      try {
        const { data } = await api.get(`/tanques/${idParam}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUnicoTanque(data);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          // Se o erro for 403 (Forbidden)      
          navigate('/')
        } else {
          // Se for um erro diferente de 403, faça o log do erro
          console.log(error);} 
      }
    };
    loadTanque();
  }, [idParam]);

  const editTanque = async (formData) => {
    const token = JSON.parse(localStorage.getItem("token"));

    try {
      const { data } = await api.put(`/tanques/updateTanqueRetangular/${idParam}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUnicoTanque(data);
    

      const updatedTanques = tanques.map((tanque) =>
      tanque.id === idParam ? { ...tanque, ...data } : tanque
    );

    setTanques(updatedTanques);


      toast.success("Tanque editado com sucesso");
      navigate("/tanques")
    } catch (error) {
      console.log(error);
      toast.error("Ops! Algo deu errado.");
    }
  };


  const submitTanque = (formData) => {
    editTanque(formData);
  };

  useEffect(() => {
    const getAllSensores = async () => {
      const token = JSON.parse(localStorage.getItem("token"));
      try {
        const { data } = await api.get("/modulos", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const sensoresRevertidos = data.reverse();
        setSensores(sensoresRevertidos);
      } catch (error) {
        console.log(error);
      }
    };

    getAllSensores();
  }, []);
  return (
    <>
      <Header />
      <div className="flexBox">
        <div className="containerPagIndividual">
          <div>
            <h3>Editar tanque</h3>
        
          </div>
          {unicoTanque ? (
            <>
              <form className="formEditar" onSubmit={handleSubmit(submitTanque)}>
              <label>
                {" "}
                Tanque Id
                <input
                  className="inputEditarSemBorda"
                  disabled
                  {...register("id")}
                  defaultValue={parseInt(unicoTanque.id)}
                />
              </label>
                <label>
                  Nome do tanque
                  <input  className="inputEditar"
                    {...register("nome")}
                    defaultValue={unicoTanque.nome}
                  />
                </label>
                {errors.nome ? <p>{errors.nome.message}</p> : null}
                <label>
                  Comprimento em cm
                  <input  className="inputEditar"
                    {...register("comprimento")}
                    defaultValue={unicoTanque.comprimento}
                  ></input>
                </label>
                {errors.comprimento ? (
                  <p>{errors.comprimento.message}</p>
                ) : null}
                <label>
                  Altura em cm
                  <input  className="inputEditar"
                    {...register("altura")}
                    defaultValue={unicoTanque.altura}
                  ></input>
                </label>
                {errors.altura ? <p>{errors.altura.message}</p> : null}
                <label>
                  Largura em cm
                  <input  className="inputEditar"
                  type='text'
                    {...register("largura")}
                    defaultValue={unicoTanque.largura}
                    name="largura"
                  ></input>
                </label>
                {errors.largura ? <p>{errors.largura.message}</p> : null}
                <label>
                  Correção em mm
                  <input  className="inputEditar"
                    {...register("correcao")}
                    defaultValue={unicoTanque.correcao}
                  ></input>
                </label>
             
                {errors.correcao ? <p>{errors.correcao.message}</p> : null}
                {/* <label>
              {" "}
              Módulo
              <select
                className="select-input"
                {...register("moduloId")}
                defaultValue={""}
              >
                <option value={""}>Selecionar módulo</option>
                {sensores.map((sensor) => (
                  <option key={sensor.id} value={sensor.id}>
                    {sensor.nome} id:{sensor.id}
                  </option>
                ))}
              </select>
            </label> */}
               
                <button className="botao" type="submit">Editar tanque</button>
              </form>
            </>
          ) : null}
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};
