import { React } from "react";
import "../../Header/Header.css";
import { Link } from "react-router-dom";


export const HeaderUser = () => {

  return (
    <>
      <div className="headerBox">
        <div className="containerUser">
          {/* <div className="navDesktop"> */}
            {/* <nav className="navBar"> */}
             
              <div className="icons">
              <Link className="btn-sair" to="/user/dashboard">
            Voltar ao painel
          </Link>
              </div>
            {/* </nav> */}
          </div>
        </div>
      {/* </div> */}
      
    </>
  );
};
