import { useContext } from "react";

import { TanqueContext } from "../../providers/TanquesContext";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FaTrash, FaPlus } from "react-icons/fa";
import { ClientsContext } from "../../providers/ClientesContext";
import { Link } from "react-router-dom";
import { api } from "../../services/api";
import { toast } from "react-toastify";
import { AuthContext } from "../AuthContext";
import { SlPeople } from "react-icons/sl";
import { GiWaterTank } from "react-icons/gi";
import { BiEdit } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import { CiCircleRemove } from "react-icons/ci";
import { MdOutlineSensors } from "react-icons/md";

export const SensoresInfoCardPainel = ({
  sensor,

}) => {



  return (
    <>



      <li className="itemCardAdminPainel">
        <p>Id: <strong>{sensor.id}</strong></p>
        <p>Nome: <strong>{sensor.nome}</strong></p>

        <div>
          <div style={{display: "flex"}}>
            <Link className="botao" to={`/editar-modulos/${sensor.id}`}>
            
              Editar módulo
            </Link>
            <Link className="botao" to={`/modulos/${sensor.id}`}>
             
              Visualizar módulo
            </Link>
          </div>
          <div>
          </div>
        </div>
      </li>

     
    </>
  );
};
