import axios from "axios"

export const api = axios.create({
    withCredentials: true,
    baseURL: "http://martplore.com.br/api",
    // baseURL: "http://191.101.18.166:8080/api",
    // baseURL: "http://localhost:8080",

    timeout: 20 * 1000
    
})

axios.defaults.withCredentials = true