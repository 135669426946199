import { useNavigate, useParams } from "react-router-dom";

import { useContext, useEffect, useState } from "react";
import { api } from "../../services/api";

import { Header } from "../Header/Header";
import { CadaSensorFormSchema } from "./ValidacaoZod/CadaSensorFormSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ClientsContext } from "../../providers/ClientesContext";
import { TanqueContext } from "../../providers/TanquesContext";
import { SensoresContext } from "../../providers/SensoresContext";
import { Footer } from "../Footer/Footer";

export const PagEditarCadaSensor = () => {
  const [unicoSensor, setUnicoSensor] = useState(null);
  const { sensores, setSensores } = useContext(SensoresContext);
  const { idParam } = useParams();
  const { clientes, setClientes } = useContext(ClientsContext);
  const { tanques, setTanques } = useContext(TanqueContext);
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    resolver: zodResolver(CadaSensorFormSchema),
  });
  const navigate = useNavigate();


  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));

    const getAllTanques = async () => {
      try {
        const { data } = await api.get("/tanques", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setTanques(data);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          // Se o erro for 403 (Forbidden)      
          navigate('/')
        } else {
          // Se for um erro diferente de 403, faça o log do erro
          console.log(error);
        } 
    };}

    getAllTanques();
  }, []);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));

    const loadSensor = async () => {
      try {
        const { data } = await api.get(`/modulos/${idParam}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUnicoSensor(data);
      } catch (error) {
        console.log(error);
      }
    };
    loadSensor();
  }, [idParam]);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));

    const getAllClientes = async () => {
      try {
        const { data } = await api.get("/clientes", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setClientes(data);
      } catch (error) {
        console.log(error);
      }
    };
    getAllClientes();
  }, []);

  const token = JSON.parse(localStorage.getItem("token"));
  const editSensor = async (formData) => {

    try {
      const { data } = await api.put(`/modulos/${idParam}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUnicoSensor(data);

      const updatedSensores = sensores.map((sensor) =>
        sensor.id === idParam ? { ...sensor, ...data } : sensor
      );

      setSensores(updatedSensores);
      toast.success("Módulo editado com sucesso");
      navigate("/modulos");
    } catch (error) {
      console.log(error);
      toast.error("Ops! Algo deu errado.");
    }
  };

  const submit = (formData) => {
    editSensor(formData);
  };
  return (
    <>
      <Header />

      <div className="containerPagIndividual">
        <p>EDITAR MÓDULO</p>

        {unicoSensor ? (
          <>
            <form className="formEditar" onSubmit={handleSubmit(submit)}>
              <label>
                {" "}
                Módulo Id
                <input
                type="text"
                  className="inputSemBorda"
             disabled
             
                  defaultValue={unicoSensor.id}
                />
              </label>

              <label>
                Nome do módulo
                <input
                type="text"
                  className="inputEditar"
                  {...register("nome")}
                  defaultValue={unicoSensor.nome}
                />
              </label>
              {errors.nome ? <p>{errors.nome.message}</p> : null}
          

              {unicoSensor.cliente !== null ? (
                <>
                  <div className="editarSelect">
                    <label>
                      {" "}
                      Cliente
                      <input
                  className="inputEditarSemBorda"
                  {...register("nomeAnterior")}
                  defaultValue={ unicoSensor.cliente.nome !== null
                    ? unicoSensor.cliente.nome
                    : "Atribuir cliente"} disabled
                />
              
                      <select
                        className="select"
                        {...register("clienteId")}
                        defaultValue={
                          unicoSensor.cliente.id !== null
                            ? unicoSensor.cliente.id
                            : ""
                        
                        }
                      >
                       
                        <option value={""}>Se deseja trocar selecione:</option>
                        {clientes.map((cliente) => (
                          <option key={cliente.id} value={cliente.id}>
                            {cliente.nome}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                </>
              ) : (
                <div className="editarSelect">
                  <label>
                    {" "}
                    Cliente
                    <select
                      className="select"
                      {...register("clienteId")}
                      defaultValue={""}
                    >
                      <option value={""}>Selecionar cliente</option>
                      {clientes.map((cliente) => (
                        <option key={cliente.id} value={cliente.id}>
                          {cliente.nome}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
              )}

              {unicoSensor.tanque !== null ? (
                <>
                  <div className="editarSelect">
                    <label>
                      {" "}
                      Tanque
                      <input
               className="inputEditarSemBorda"
               {...register("tanqueAnterior")}
               defaultValue={ unicoSensor.tanque.nome !== null
                 ? unicoSensor.tanque.nome
                 : "Atribuir cliente"} disabled
             />
                      <select
                        className="select"
                        {...register("tanqueId")}
                        defaultValue={unicoSensor.tanque.id}
                      >
                        <option value={""}>Se deseja trocar selecione:</option>
                        {tanques.map((tanque) => (
                          <option key={tanque.id} value={tanque.id}>
                            {tanque.nome} id: {tanque.id}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                </>
              ) : (
                <>
                  <div className="editarSelect">
                    <label>
                      {" "}
                      Tanque
                      <select
                        className="select"
                        {...register("tanqueId")}
                        defaultValue={""}
                      >
                        <option value={""}>Selecionar tanque</option>
                        {tanques.map((tanque) => (
                          <option key={tanque.id} value={tanque.id}>
                            {tanque.nome} id: {tanque.id}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                </>
              )}
              <button className="botao" type="submit">
                Editar módulo
              </button>
            </form>
          </>
        ) : null}
      </div>

      {/* <Footer /> */}
    </>
  );
};
