import { React, useContext, useEffect, useState } from "react";
import "../../Header/Header.css";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { api } from "../../../services/api";

export const HeaderUserGraficos = () => {
    const { idParam } = useParams();
    const [unicoSensorId, setUnicoSensorId] = useState({});
    
    
    const loadSensor = async () => {
        const token = JSON.parse(localStorage.getItem("token"));
    
   
        try {
          const { data } = await api.get(`/modulos/${idParam}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setUnicoSensorId(data.id);
        
        } catch (error) {
          console.log(error);
        }
      };

      useEffect(() => {
    
        loadSensor();
      }, []);
   
  return (
    <>
      <div className="headerBox">
        <div className="containerUser">
          {/* <div className="navDesktop"> */}
            {/* <nav className="navBar"> */}
             
              <div className="icons">
              <Link className="btn-sair" to={`/user/dashboard/${unicoSensorId}`}>
                Voltar ao módulo
              </Link>
              </div>
            {/* </nav> */}
          {/* </div> */}
        </div>
      </div>
      
    </>
  );
};
