// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .input-container input {
    width: 92%;
    padding: 10px;
    margin: 2px 0;
    border: 1px solid var(--color-primary);
    border-radius: 5px;
    color: var(--color-primary);
  }
  
  .input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .error {
    border-color: red !important;
  }
  
  .error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  
 
  
  ul {
    margin-top: 10px;
    padding-left: 20px;
    list-style-type: none;
  }
  
  .sensorContainerBox  {

    padding: 10px;
    margin: 2px 0;
  background-color: var(--color-primary);
    border-radius: 5px;
    color: white;
  }


  .sensor-info-container {
    display: inline-block;
    border-radius: 10px;
    padding: 15px;
    background-color: #222324;
    padding: 20px 44px;
    margin: 20px;
  
    }

    .flexBox {
      min-height: 100vh;
      padding-bottom: 20px;
      overflow-y: scroll;
      /* height: 100vh; */
    }
    

    @media(max-width: 730px){
      .flexBox{
        overflow-y: scroll;
      }
    }

  

`, "",{"version":3,"sources":["webpack://./src/components/Sensores/Sensores.css"],"names":[],"mappings":";EACE;IACE,UAAU;IACV,aAAa;IACb,aAAa;IACb,sCAAsC;IACtC,kBAAkB;IAClB,2BAA2B;EAC7B;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,4BAA4B;EAC9B;;EAEA;IACE,UAAU;IACV,eAAe;IACf,eAAe;EACjB;;;;EAIA;IACE,gBAAgB;IAChB,kBAAkB;IAClB,qBAAqB;EACvB;;EAEA;;IAEE,aAAa;IACb,aAAa;EACf,sCAAsC;IACpC,kBAAkB;IAClB,YAAY;EACd;;;EAGA;IACE,qBAAqB;IACrB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;;IAEZ;;IAEA;MACE,iBAAiB;MACjB,oBAAoB;MACpB,kBAAkB;MAClB,mBAAmB;IACrB;;;IAGA;MACE;QACE,kBAAkB;MACpB;IACF","sourcesContent":["\r\n  .input-container input {\r\n    width: 92%;\r\n    padding: 10px;\r\n    margin: 2px 0;\r\n    border: 1px solid var(--color-primary);\r\n    border-radius: 5px;\r\n    color: var(--color-primary);\r\n  }\r\n  \r\n  .input-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n  }\r\n  \r\n  .error {\r\n    border-color: red !important;\r\n  }\r\n  \r\n  .error-message {\r\n    color: red;\r\n    font-size: 12px;\r\n    margin-top: 5px;\r\n  }\r\n  \r\n \r\n  \r\n  ul {\r\n    margin-top: 10px;\r\n    padding-left: 20px;\r\n    list-style-type: none;\r\n  }\r\n  \r\n  .sensorContainerBox  {\r\n\r\n    padding: 10px;\r\n    margin: 2px 0;\r\n  background-color: var(--color-primary);\r\n    border-radius: 5px;\r\n    color: white;\r\n  }\r\n\r\n\r\n  .sensor-info-container {\r\n    display: inline-block;\r\n    border-radius: 10px;\r\n    padding: 15px;\r\n    background-color: #222324;\r\n    padding: 20px 44px;\r\n    margin: 20px;\r\n  \r\n    }\r\n\r\n    .flexBox {\r\n      min-height: 100vh;\r\n      padding-bottom: 20px;\r\n      overflow-y: scroll;\r\n      /* height: 100vh; */\r\n    }\r\n    \r\n\r\n    @media(max-width: 730px){\r\n      .flexBox{\r\n        overflow-y: scroll;\r\n      }\r\n    }\r\n\r\n  \r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
