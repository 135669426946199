import { React, useContext } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import { AiOutlineMenu } from "react-icons/ai";
import { MenuMobile } from "./MenuMobile";
import { RxDashboard } from "react-icons/rx";
import { SlPeople } from "react-icons/sl";
import { MdOutlineSensors } from "react-icons/md";
import { GiWaterTank } from "react-icons/gi";
import { BiLogOut } from "react-icons/bi";

export const Header = () => {
  const {
    setUsername,
    setPassword,
    setUser,
    setUserRole,
    isMenuHamburguerVisible,
    setIsMenuHamburguerVisible,
  } = useContext(AuthContext);
  const handleLogout = () => {
    setUser({});
    setUsername("");
    setPassword("");
    setUserRole("");
    localStorage.clear();
  };

  return (
    <>
      <div className="headerBox">
        <div className="container">
          <div className="navDesktop">
            <nav className="navBar">
              <div className="icons">
                <Link className="linkNavDesktop" to="/admin/dashboard">
                  <RxDashboard className="menuIconsDash" size={35} />{" "}
                  <p className="menuItensDash">Painel</p>
                </Link>
              </div>
              <div className="icons">
                <Link className="linkNavDesktop" to="/modulos">
                  <MdOutlineSensors className="menuIconsSensor" size={35} />{" "}
                  <p className="menuItensSensor">Módulos</p>
                </Link>
              </div>
              <div className="icons">
                <Link className="linkNavDesktop" to="/clientes">
                  <SlPeople className="menuIconsClient" size={35} />{" "}
                  <p className="menuItensClient">Clientes</p>
                </Link>
              </div>
              <div className="icons">
                <Link className="linkNavDesktop" to="/tanques">
                  <GiWaterTank className="menuIconsTanque" size={35} />
                  <p className="menuItensTanque">Tanques</p>
                </Link>
              </div>
              <div className="icons">
                <Link className="linkNavDesktop" to="/" onClick={handleLogout}>
                  <BiLogOut className="menuIconsLogout" size={35} />
                  <p className="menuItensLogout"> Sair</p>
                </Link>
              </div>
            </nav>
          </div>

          <div className="navMobileAll">
            <button
              onClick={() => setIsMenuHamburguerVisible(true)}
              className="botao-menu"
            >
              <AiOutlineMenu size={25} />
            </button>
          </div>
        </div>
      </div>
      {isMenuHamburguerVisible ? <MenuMobile /> : null}
    </>
  );
};
