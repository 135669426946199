import React, { createContext, useState } from "react";

export const TanqueContext = createContext();

export const TanqueProvider = ({ children }) => {
    const [tanques, setTanques] = useState([]);
    const [tanqueDeleteId, setTanqueDeleteId] = useState("")
  return (
    <TanqueContext.Provider
      value={{
        tanques, setTanques, tanqueDeleteId, setTanqueDeleteId
      }}
    >
      {children}
    </TanqueContext.Provider>
  );
};

