import { z } from "zod";

export const ClientesFormSchema = z.object({
  nome: z.string().nonempty("O nome é obrigatório"),
  username: z
    .string()
    .trim("Não pode conter espaços")
    .nonempty("O username é obrigatório"),
    email: z.string().nonempty("O e-mail é obrigatório"),
  password: z
    .string()
    .nonempty("A senha é obrigatória")
    .min(6, "São necessários pelo menos seis caracteres"),
  cpf: z.string(),
  cnpj: z.string(),
});
