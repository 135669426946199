// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cliente-info-container {
  display: inline-block;
  border-radius: 10px;
  padding: 15px;
  background-color: #222324;
  padding: 20px 44px;
  margin: 20px 0px;

  }
  
  .info-section {
    margin-top: 10px;
  }
  
  .add-info {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .remove-info-button {
    background: none;
    border: none;
    cursor: pointer;
    color: red;
    font-size: 12px;
  }
  
  .add-info-button {
    background-color: var(--color-beige2);
    border: none;
    color: white;
    border-radius: 5px;
    padding: 3px 8px;
    margin-left: 5px;
    cursor: pointer;
  }

  .add-info-button:hover{
    background-color: var(--color-primary);
  }
  
  .remove-button {
    margin-top: 32px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
.client{
  display: flex;
  gap: 10px;
}

.clienteBotoes{
display: flex;
}

@media (min-width: 730px) {
.botoesClienteInfo{
  width: 30%;
}

}`, "",{"version":3,"sources":["webpack://./src/components/Clientes/ClienteInfo.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;;EAEhB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,UAAU;IACV,eAAe;EACjB;;EAEA;IACE,qCAAqC;IACrC,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;EACjB;;EAEA;IACE,sCAAsC;EACxC;;EAEA;IACE,gBAAgB;IAChB,qBAAqB;IACrB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;EACjB;;AAEF;EACE,aAAa;EACb,SAAS;AACX;;AAEA;AACA,aAAa;AACb;;AAEA;AACA;EACE,UAAU;AACZ;;AAEA","sourcesContent":[".cliente-info-container {\r\n  display: inline-block;\r\n  border-radius: 10px;\r\n  padding: 15px;\r\n  background-color: #222324;\r\n  padding: 20px 44px;\r\n  margin: 20px 0px;\r\n\r\n  }\r\n  \r\n  .info-section {\r\n    margin-top: 10px;\r\n  }\r\n  \r\n  .add-info {\r\n    display: flex;\r\n    align-items: center;\r\n    margin-top: 10px;\r\n  }\r\n  \r\n  .remove-info-button {\r\n    background: none;\r\n    border: none;\r\n    cursor: pointer;\r\n    color: red;\r\n    font-size: 12px;\r\n  }\r\n  \r\n  .add-info-button {\r\n    background-color: var(--color-beige2);\r\n    border: none;\r\n    color: white;\r\n    border-radius: 5px;\r\n    padding: 3px 8px;\r\n    margin-left: 5px;\r\n    cursor: pointer;\r\n  }\r\n\r\n  .add-info-button:hover{\r\n    background-color: var(--color-primary);\r\n  }\r\n  \r\n  .remove-button {\r\n    margin-top: 32px;\r\n    background-color: red;\r\n    color: white;\r\n    border: none;\r\n    border-radius: 5px;\r\n    padding: 5px 10px;\r\n    cursor: pointer;\r\n  }\r\n  \r\n.client{\r\n  display: flex;\r\n  gap: 10px;\r\n}\r\n\r\n.clienteBotoes{\r\ndisplay: flex;\r\n}\r\n\r\n@media (min-width: 730px) {\r\n.botoesClienteInfo{\r\n  width: 30%;\r\n}\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
