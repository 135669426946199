import { z } from "zod";

export const TanquesCilindricoFormSchema = z
  .object({
    nome: z.string().nonempty("O nome é obrigatório"),
    comprimento: z
      .string()
      .nonempty("O comprimento é obrigatório"),
      raio: z
      .string()
      .nonempty("O raio é obrigatório"),
      correcao: z
      .string()
      .nonempty("A correção é obrigatória. Se não tiver inserir número 0"),

  })
 


