import React, { createContext, useState } from "react";

export const SensoresContext = createContext();

export const SensoresProvider = ({ children }) => {

    const [sensores, setSensores] = useState([]);
  return (
    <SensoresContext.Provider
      value={{
       sensores, setSensores
      }}
    >
      {children}
    </SensoresContext.Provider>
  );
};
