// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  text-align: center;
  color: var(--item-card-color);
  position: relative;
left:0;
bottom:0;
right:0;
}

/* .spacing {
  height: 600px;
  background-color: var(--color-primary);
  z-index: -1;
} */
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,6BAA6B;EAC7B,kBAAkB;AACpB,MAAM;AACN,QAAQ;AACR,OAAO;AACP;;AAEA;;;;GAIG","sourcesContent":[".footer {\r\n  height: 200px;\r\n  width: 100%;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  background: black;\r\n  text-align: center;\r\n  color: var(--item-card-color);\r\n  position: relative;\r\nleft:0;\r\nbottom:0;\r\nright:0;\r\n}\r\n\r\n/* .spacing {\r\n  height: 600px;\r\n  background-color: var(--color-primary);\r\n  z-index: -1;\r\n} */\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
