import { React, useContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthContext } from "./components/AuthContext";
import Login from "./components/Login/Login";
import Clientes from "./components/Clientes/Clientes";
import Tanques from "./components/Tanques/Tanques";
import Sensores from "./components/Sensores/Sensores";
import InicioUser from "./components/Inicio/InicioUser/InicioUser";
import { Loading } from "./components/Loading/Loading";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PagEditarCadaSensor } from "./components/PaginaIndividual/PagEditarCadaSensor";
import { PagEditarCadaTanqueEmPe } from "./components/PaginaIndividual/PagEditarCadaTanqueEmPe";
import { PagEditarCadaCliente } from "./components/PaginaIndividual/PagEditarCadaCliente";
import { PagVisualizarCadaSensor } from "./components/PaginaIndividual/PagVisualizarCadaSensor";
import { Painel1 } from "./components/Painel/Painel";
import { PagCadaClienteSensores } from "./components/PaginaIndividual/PagCadaClienteSensores";
import { PrivateClienteRoutes } from "./routes/PrivateClienteRoutes";
import { PrivateAdminRoutes } from "./routes/PrivateAdminRoutes";
import { ForbiddenPage } from "./components/ErrorPage/ForbiddenPage";
import { LeiturasUser } from "./components/Inicio/InicioUser/LeiturasUser";
import { AdicionarCilindricoEmPe } from "./components/Tanques/AdicionarCilindricoEmPe";
import { AdicionarCilindricoDeitado } from "./components/Tanques/AdicionarCilindricoDeitado";
import { AdicionarRetangular } from "./components/Tanques/AdicionarRetangular";
import { PagEditarCadaTanqueDeitado } from "./components/PaginaIndividual/PagEditarCadaTanqueDeitado";
import { PagEditarCadaTanqueRetangular } from "./components/PaginaIndividual/PagEditarCadaTanqueRetangular";
import { PagVisualizarCadaCliente } from "./components/PaginaIndividual/PagVisualizarCadaCliente";
import { PagVisualizarGraficos } from "./components/Graficos/PagVisualizarGraficos";
import { PagVisualizarGraficosUser } from "./components/Graficos/PagVisualizarGraficosUser";
import TodasLeituras from "./components/Leituras/Leituras";
import { Funcionarios } from "./components/Inicio/InicioUser/FuncionariosUser";

const App = () => {
  const { loading, userRole, user } = useContext(AuthContext);

  if (loading) {
    return <Loading />;
  }

  // Dentro do componente funcional

  return (
    <div className="footer-repair">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/leituras" element={<TodasLeituras />} />
          <Route path="*" element={<Login />} />
          {!user ? <Route path="*" element={<Login />} /> : null}{" "}
          {/* {!userRole ? <Route path="*" element={<ForbiddenPage />} /> : null} */}
          {!userRole && (
            <>
              <Route path="/user/dashboard" element={<InicioUser />} />
              <Route
                path="/user/dashboard/:idParam"
                element={<LeiturasUser />}
              />
              <Route
                path="/user/dashboard/:idParam/funcionarios"
                element={<Funcionarios />}
              />
              <Route
                path="/user/dashboard/:idParam/graficos"
                element={<PagVisualizarGraficosUser />}
              />
            </>
          )}
          {userRole && (
            <>
         
              <Route path="/admin/dashboard" element={<Painel1 />} />
              <Route path="/clientes" element={<Clientes />} />
              <Route path="/tanques" element={<Tanques />} />
              <Route path="/modulos" element={<Sensores />} />
              <Route
                path="/editar-modulos/:idParam"
                element={<PagEditarCadaSensor />}
              />
              <Route
                path="/modulos/:idParam"
                element={<PagVisualizarCadaSensor />}
              />
              <Route
                path="/modulos/:idParam/graficos"
                element={<PagVisualizarGraficos />}
              />

              <Route
                path="/tanques-cilindrico-em-pe/:idParam"
                element={<PagEditarCadaTanqueEmPe />}
              />
              <Route
                path="/tanques-cilindrico-deitado/:idParam"
                element={<PagEditarCadaTanqueDeitado />}
              />
              <Route
                path="/tanques-retangular/:idParam"
                element={<PagEditarCadaTanqueRetangular />}
              />
              <Route
                path="/tanques/cilindrico-em-pe"
                element={<AdicionarCilindricoEmPe />}
              />
              <Route
                path="/tanques/cilindrico-deitado"
                element={<AdicionarCilindricoDeitado />}
              />
              <Route
                path="/tanques/retangular"
                element={<AdicionarRetangular />}
              />
              <Route
                path="/clientes/:idParam"
                element={<PagVisualizarCadaCliente />}
              />
              <Route
                path="/clientes-editar/:idParam"
                element={<PagEditarCadaCliente />}
              />
              <Route
                path="/cliente-modulos/:idParam"
                element={<PagCadaClienteSensores />}
              />
            </>
          )}
        </Routes>
      </Router>

      <ToastContainer theme="dark" autoClose={2000} />
    </div>
  );
};

export default App;
