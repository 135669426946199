import { z } from "zod";

export const CadaClienteFormSchema = z.object({
  id: z.string(),
  nome: z.string().nonempty("O nome é obrigatório"),
  username: z.string().nonempty("O usuário é obrigatório"),
  password: z
  .string()
  .nonempty("A senha é obrigatória")
  .min(6, "São necessários pelo menos seis caracteres"),
isActive: z.string(),
email: z.string(),
  cpf: z.string(),
  cnpj: z.string(),
});
