import { AuthContext } from "../../AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import "./InicioUser.css";

import { MdOutlineSensors, MdSearch } from "react-icons/md";

import { SensorUserCard } from "./SensorUserCard";
import { useForm } from "react-hook-form";

import { HeaderInicioUser } from "./HeaderInicioUser";
import { Loading } from "../../Loading/Loading";
import { api } from "../../../services/api";

const InicioUser = () => {
  const { idParam } = useParams();
  const [searchSensor, setSearchSensor] = useState("");
  const [nomeCliente, setNomeCliente] = useState(null);
  const { register, handleSubmit, reset } = useForm();
  const [allSensoresList, setAllSensoresList] = useState([]);
  const [filteredSensores, setFilteredSensores] = useState([]);
  const { setUser, setUsername, setPassword, setUserRole } =
    useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  document.body.classList.remove("background-red");
  const id = localStorage.getItem("ID");

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    const getAllSensores = async () => {
      try {
        const { data } = await api.get(`/clientes/${id}/lista-sensores`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAllSensoresList(data);
        const role = JSON.parse(localStorage.getItem("admin"));

        if (role) {
          navigate("/login");
        } else if (role === false) {
          navigate("/user/dashboard");
        } else {
          navigate("/login");
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          // Se o erro for 403 (Forbidden)
          navigate("/login");
        } else {
          // Se for um erro diferente de 403, faça o log do erro
          console.log(error);
        }
      } finally {
        setLoading(false);
      }
    };
    getAllSensores();
  }, []);

  useEffect(() => {
    const filteredSensoresList = allSensoresList.filter(
      (sensor) =>
        sensor.nome.toLowerCase().includes(searchSensor.toLowerCase()) ||
        sensor.id == searchSensor
    );

    setFilteredSensores(filteredSensoresList);
  }, [searchSensor, allSensoresList]);

  const submitSearch = (data) => {
    reset({ searchSensor: "" });
    setSearchSensor(searchSensor);
  };

  const sensoresList = searchSensor ? filteredSensores : allSensoresList;

  const nome = JSON.parse(localStorage.getItem("nomeCliente"));

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <HeaderInicioUser />
      <div className="dashContainerBox body-user">
        <div>
          <div className="h2-user">
            <h2>
              <MdOutlineSensors className="icon" size={35} /> MÓDULOS
            </h2>
            <p>Olá, {nome ? nome : null}</p>
            <Link
              to={`/user/dashboard/${id}/funcionarios`}
              className="botao btn visualizar"
            >
              Visualizar funcionários
            </Link>
          </div>
          <div className="containerSearchUser">
            <p>Buscar módulo por nome ou Id</p>
            <form onSubmit={handleSubmit(submitSearch)} className="formSearch">
              <input
                type="text"
                {...register("searchSensor")}
                value={searchSensor}
                placeholder="Digite Id ou nome do módulo"
                onChange={(e) => setSearchSensor(e.target.value)}
              />
              <button type="submit" className="btnSearch">
                <MdSearch size={21} />
              </button>
            </form>
          </div>

          {loading ? (
            <div className=" container ">
              <p
                style={{
                  position: "relative",
                  justifyContent: "center",
                  color: "white",
                  margin: "30px",
                  padding: "34px",
                }}
              >
                Carregando...
              </p>
            </div>
          ) : (
            <>
              <div className="container">
                {sensoresList?.length === 0 ? (
                  <p
                    style={{
                      position: "relative",
                      justifyContent: "center",
                      color: "white",
                      margin: "30px",
                      padding: "34px",
                    }}
                  >
                    Nenhum resultado encontrado.
                  </p>
                ) : (
                  <ul className="lista">
                    {sensoresList.map((sensor) => (
                      <SensorUserCard key={sensor.id} sensor={sensor} />
                    ))}
                  </ul>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default InicioUser;
