import { useContext } from "react";

import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../components/AuthContext";

export const PrivateClienteRoutes = () => {
  const { userRole } = useContext(AuthContext);


  
  return !userRole ? <Outlet /> : <Navigate to="/login" />;
};
