import React, { useContext, useEffect, useState } from "react";
import "./Tanques.css"; // Import the component-specific styling
import { Header } from "../Header/Header";
import { useForm } from "react-hook-form";
import { TanquesInfo } from "./TanquesInfo";
import { AuthContext } from "../AuthContext";
import { api } from "../../services/api";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TanquesFormSchema } from "./ValidacoesZod/TanquesEmPeFormSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { TanqueContext } from "../../providers/TanquesContext";
import { GiWaterTank } from "react-icons/gi";
import { Footer } from "../Footer/Footer";
import { MdSearch } from "react-icons/md";

const Tanques = () => {
  const [isAddingTanque, setIsAddingTanque] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [tanquesComModais, setTanquesComModais] = useState({});
  const { userRole } = useContext(AuthContext);
  const [searchTanque, setSearchTanque] = useState("");
  const [filteredTanques, setFilteredTanques] = useState([]);
  const { tanques, setTanques, tanqueDeleteId, setTanqueDeleteId } =
    useContext(TanqueContext);
    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    if (userRole) {
      navigate("/tanques");
    } else {
      navigate("/login");
    }
  }, [navigate, userRole]);

  useEffect(() => {
    const getAllTanques = async () => {
      const token = JSON.parse(localStorage.getItem("token"));
      setLoading(true);
      try {
        const { data } = await api.get("/tanques", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const tanquesRevertidos = data.reverse();
        setTanques(tanquesRevertidos);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          // Se o erro for 403 (Forbidden)      
          navigate('/')
        } else {
          // Se for um erro diferente de 403, faça o log do erro
          console.log(error);
        }} finally {
          setLoading(false);
    }; }

    getAllTanques();
  }, []);

  const deleteTanque = async () => {
    const token = JSON.parse(localStorage.getItem("token"));

    try {
      await api.delete(`/tanques/${tanqueDeleteId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const newTanquesList = tanques.filter(
        (tanque) => tanque.id !== tanqueDeleteId
      );
      setTanques(newTanquesList);
      toast.success("Tanque excluído com sucesso");
      setShowDeleteConfirmation(false);
    } catch (error) {
      console.log(tanqueDeleteId);
      console.log(error);
      toast.error("Ops alguma coisa deu errado!");
    }
  };

  const confirmRemoveTanque = (tanqueId) => {
    setTanquesComModais({ ...tanquesComModais, [tanqueId]: true });

    setTanqueDeleteId(tanqueId);
    setShowDeleteConfirmation(true);
  };

  const submitSearch = (data) => {
    reset({ searchTanque: "" });
    setSearchTanque(searchTanque);
  };

  const tanquesList = searchTanque ? filteredTanques || [] : tanques || [];

  useEffect(() => {
    const filteredTanquesList = tanques.filter((tanque) =>
      tanque.nome.toLowerCase().includes(searchTanque.toLowerCase())
    );
    setFilteredTanques(filteredTanquesList);
  }, [searchTanque, tanques]);

  return (
    <>
      <Header />
      <div className="flexBox">
        <div className={isAddingTanque ? "containerBox" : "tanqueContainerBox"}>
          {isAddingTanque ? (
            <>
              <h2>Tanques</h2>
              <div className="input-container">
                <Link
                  to="/tanques/cilindrico-em-pe"
                  className="custom-button-tanque button"
                >
                  Cadastrar Tanque Cilindrico Vertical
                </Link>{" "}
                <Link
                  to="/tanques/cilindrico-deitado"
                  className="custom-button-tanque button"
                >
                  Cadastrar Tanque Cilindrico Horizontal
                </Link>{" "}
                <Link to="/tanques/retangular" className="custom-button-tanque button">
                  Cadastrar Tanque Retangular
                </Link>
                <button
                  className="cancel-button-cliente"
                  onClick={() => setIsAddingTanque(false)}
                >
                  Cancelar
                </button>
              </div>
            </>
          ) : (
            <div className="botaoBox">
              <div className="titlePag">
                <GiWaterTank size={45} />

                <h2>Tanques</h2>
              </div>
              <div className="searchBoxFlex">
                <button
                  className="botao-add"
                  onClick={() => setIsAddingTanque(true)}
                >
                  Adicionar Tanque
                </button>
                <form
                  onSubmit={handleSubmit(submitSearch)}
                  className="formSearch"
                >
                  <input
                    type="text"
                    {...register("searchTanque")}
                    value={searchTanque}
                    placeholder="Digite o nome do tanque"
                    onChange={(e) => setSearchTanque(e.target.value)}
                  />
                  <button type="submit" className="btnSearch">
                    <MdSearch size={21} />
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>

        {loading ? (
          <>
            <div className=" container ">
              <p
                style={{
                  position: "relative",
                  justifyContent: "center",
                  color: "white",
                  margin: "30px",
                  padding: "34px",
                }}
              >
                Carregando...
              </p>
            </div>
          </>
        ) : (
          <>
        <div className="container">
          {tanquesList.length === 0 ? (
            <p
              style={{
                position: "relative",
                justifyContent: "center",
                color: "white",
                margin: "30px",
                padding: "34px",
              }}
            >
              Nenhum resultado encontrado.
            </p>
          ) : (
            <ul className="listas">
              {tanquesList.map((tanque) => (
                <TanquesInfo
                  tanquesComModais={tanquesComModais}
                  deleteTanque={deleteTanque}
                  confirmRemoveTanque={confirmRemoveTanque}
                  key={tanque.id}
                  tanque={tanque}
                  setTanquesComModais={setTanquesComModais}
                />
              ))}
            </ul>
          )}
        </div>
        </>
        )}
      </div>
  
    </>
  );
};

export default Tanques;
