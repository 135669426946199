/* eslint-disable no-loop-func */
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { api } from "../../services/api";
import { Header } from "../Header/Header";
import Charts from "../Graficos/Charts";
import "./styles/graficos.css";
import moment from "moment";

export const PagVisualizarGraficos = () => {
  const [unicoSensor, setUnicoSensor] = useState({});
  const [loading, setLoading] = useState(true);
  const { idParam } = useParams();
  const [ultimaLeitura, setUltimaLeitura] = useState(null);
  const [ultimaLeituraData, setUltimaLeituraData] = useState(null);
  const [leituras, setLeituras] = useState([]);
  const [sensorID, setSensorID] = useState(null);
  const [idTanque, setIdTanque] = useState(null);
  const [todosVolumes, setTodosVolumes] = useState([]);
  const [comprimentoTanque, setComprimentoTanque] = useState(0);
  const [ultimoVolume, setUltimoVolume] = useState(null);
  const [ultimoVolumeApi, setUltimoVolumeApi] = useState(null);
  const [ultimaTemperatura, setUltimaTemperatura] = useState(null);
  const [abastecimento, setAbastecimento] = useState(false);
  const [qntAbastecido, setQntAbastecido] = useState(null);
  const [totalizador, setTotalizador] = useState(null);
  const [tanque, setTanque] = useState({});
  const [graphsIsLoaded, setGraphIsLoaded] = useState(false);
  const [dados, setDados] = useState({});
  const [alturaCorrigida, setAlturaCorrigida] = useState(null);
  const [tds, setTds] = useState(null);
  const [porcentagemVolume, setPorcentagemVolume] = useState(null);
  const [carregouDados, setCarregouDados] = useState(false);
  const [capacidadeTotal, setCapacidadeTotal] = useState(null);
  const [LeiturasAbastecimento, setLeiturasAbastecimento] = useState(null);
  const [leituraComDataFormatada, setLeituraComDataFormatada] = useState([]);
  const [tipoDados, setTipoDados] = useState("volume");
  const [diasDoGrafico, setDiasDoGrafico] = useState("");

  const [botao30DiasSelecionado, setBotao30DiasSelecionado] = useState(true);
  const [botao7DiasSelecionado, setBotao7DiasSelecionado] = useState(false);
  const [botao24, setBotao24] = useState(false);
  const [botaoTDS, setBotaoTDS] = useState(false);
  const [botaoTemperatura, setBotaoTemperatura] = useState(false);
  const [botaoVolume, setBotaoVolume] = useState(true);
  const [apiData, setApiData] = useState([]);
  const [porcentagens, setPocentagens] = useState([]);
  const [datas, setDatas] = useState([]);
  const navigate = useNavigate();
  // useEffect(() => {

  const loadSensor = async () => {
    const token = JSON.parse(localStorage.getItem("token"));

    // setLoading(true);
    try {
      const { data } = await api.get(`/modulos/${idParam}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUnicoSensor(data);
      setSensorID(data.id);

      setGraphIsLoaded(true);
      const leiturasTotais = data.leituras;

      setBotaoVolume(false);
      setBotao30DiasSelecionado(false);
      //   const datasFiltradas = leiturasComDataFormatada.filter(
      //     (leitura) =>
      //      leitura.data > Date.now() - 30 * 24 * 60 * 60 * 1000
      //   );
      const datasFiltradas = leiturasTotais.filter((leitura) => {
        const dataLeitura = Date.parse(leitura.data);

        return leitura.data > Date.now() - 30 * 24 * 60 * 60 * 1000;
      });

      const filtrarUltimasLeiturasPorDia = (leituras) => {
        const leiturasPorDia = {};

        leituras.map((leitura) => {
          const dataSemHora = new Date(leitura.data);
          dataSemHora.setHours(0, 0, 0, 0);
          const chave = dataSemHora.toString(); // Usar a representação em string da data como chave

          if (
            !leiturasPorDia[chave] ||
            leitura.data > leiturasPorDia[chave].data
          ) {
            leiturasPorDia[chave] = leitura;
          }
        });

        return Object.values(leiturasPorDia);
      };

      const leiturasUnicas = filtrarUltimasLeiturasPorDia(datasFiltradas);

      const porcentagens = leiturasUnicas.map(
        (leitura) => leitura.porcentagemVolume
      );

      setPocentagens((prevLeituras) => [...prevLeituras, ...porcentagens]);

      const datas = leiturasUnicas.map((leitura) => leitura.data);
      setDatas((prevLeituras) => [...prevLeituras, ...datas]);

      if (!carregouDados) {
        setDados({
          labels: datas,
          datasets: [
            {
              label: "Selecione os parâmetros",

              borderColor: "transparent",
              borderWidth: 2,
              backgroundColor: "transparent",
              pointRadius: 0,
            },
          ],
        });
      }
      setCarregouDados(true);
      //   setCarregouDados(true);

      const valorRetornadoPelaApi = Number(data.ultimaLeitura.volume);
      const valorFormatado = valorRetornadoPelaApi.toFixed(2);
      setUltimoVolume(valorFormatado);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        // Se o erro for 403 (Forbidden)
        navigate("/login");
      } else {
        // Se for um erro diferente de 403, faça o log do erro
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const getDatas = async () => {
    const token = JSON.parse(localStorage.getItem("token"));

    try {
      const { data } = await api.get(`/modulos/${idParam}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const leituras = data.leituras;
      const ids = leituras.map((leitura) => leitura.id);

      const datas = leituras.map((leitura) => leitura.data);

      const dataFormatada = [];

      const horário = datas.map((data) => {
        const [dia, hora] = data.split(" ");
        return hora;
      });
      const dataCompleta = datas.map((data) => {
        const [dia, hora] = data.split(" ");
        return dia;
      });

      const dia = dataCompleta.map((data) => {
        const [dia, mês, ano] = data.split("/");
        return dia;
      });
      const mês = dataCompleta.map((data) => {
        const [dia, mês, ano] = data.split("/");
        return mês;
      });
      const ano = dataCompleta.map((data) => {
        const [dia, mês, ano] = data.split("/");
        return ano;
      });

      const hora = horário.map((horario) => {
        const [hora, minuto, segundo] = horario.split(":");
        return hora;
      });
      const minuto = horário.map((horario) => {
        const [hora, minuto, segundo] = horario.split(":");
        return minuto;
      });
      const segundo = horário.map((horario) => {
        const [hora, minuto, segundo] = horario.split(":");
        return segundo;
      });

      const leituraData = [];

      for (let i = 0; i < datas.length; i++) {
        const data = new Date(
          +ano[i],
          +mês[i] - 1,
          +dia[i],
          +hora[i],
          +minuto[i],
          +segundo[i]
        );
        dataFormatada.push(data);
      }

      for (let i = 0; i < leituras.length; i++) {
        leituraData.push({ ...leituras[i], dataFormatada: dataFormatada[i] });
      }

      const colocarData = () => {
        setLeituraComDataFormatada(() => {
          return leituraData;
        });
      };
      colocarData();
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const mudarPraTemperatura = () => {
    setBotaoTDS(false);
    setBotaoTemperatura(true);
    setBotaoVolume(false);
    if (diasDoGrafico === "1") {
      setBotao7DiasSelecionado(false);
      setBotao30DiasSelecionado(false);
      setBotao24(true);

      const filtrarUltimasLeiturasPorHora = (leituras) => {
        const leiturasPorHora = {};
        const ultimas24Horas = Date.now() - 24 * 60 * 60 * 1000;

        leituras.map((leitura) => {
          // Obtenha a hora arredondada da leitura
          const horas = new Date(leitura.dataFormatada);
          const horaArredondada = new Date(
            horas.getFullYear(),
            horas.getMonth(),
            horas.getDate(),
            horas.getHours(),
            0, // Zera minutos
            0, // Zera segundos
            0 // Zera milissegundos
          );

          // Se a leitura está dentro das últimas 24 horas
          if (leitura.dataFormatada >= ultimas24Horas) {
            // Se não há leitura anterior para essa hora OU
            // a diferença de tempo (em horas) entre a leitura atual e a leitura anterior é maior que 1 hora
            if (
              !leiturasPorHora[horaArredondada] ||
              Math.abs(
                (leitura.dataFormatada -
                  leiturasPorHora[horaArredondada].dataFormatada) /
                  (60 * 60 * 1000)
              ) >= 1
            ) {
              leiturasPorHora[horaArredondada] = leitura;
            }
          }
        });

        return Object.values(leiturasPorHora);
      };
      const datasFiltradasSemOrdem = leituraComDataFormatada.filter(
        (leitura) =>
          leitura.dataFormatada.getTime() >= Date.now() - 24 * 60 * 60 * 1000
      );

      const datasFiltradas = filtrarUltimasLeiturasPorHora(
        datasFiltradasSemOrdem
      );
      const leiturasUnicas = datasFiltradas.sort((a, b) =>
        a.id > b.id ? 1 : -1
      );

      setDados({
        labels: leiturasUnicas.map((leitura) => leitura.data),
        datasets: [
          {
            label: "Temperaturas",
            data: leiturasUnicas.map((leitura) => leitura.temp),
            borderColor: "black",
            borderWidth: 2,
            backgroundColor: "blue",
            pointRadius: 0,
          },
        ],
      });
    } else if (diasDoGrafico === "30") {
      setBotao7DiasSelecionado(false);
      setBotao30DiasSelecionado(true);
      setBotao24(false);

      const datasFiltradasSemOrdem = leituraComDataFormatada.filter(
        (leitura) =>
          leitura.dataFormatada.getTime() >
          Date.now() - 30 * 24 * 60 * 60 * 1000
      );
      const filtrarUltimasLeiturasPorDia = (leituras) => {
        const leiturasPorDia = {};

        leituras.map((leitura) => {
          const dataSemHora = new Date(leitura.dataFormatada);
          dataSemHora.setHours(0, 0, 0, 0);

          if (
            !leiturasPorDia[dataSemHora] ||
            leitura.dataFormatada > leiturasPorDia[dataSemHora].dataFormatada
          ) {
            leiturasPorDia[dataSemHora] = leitura;
          }
        });

        return Object.values(leiturasPorDia);
      };

      const datasFiltradas = filtrarUltimasLeiturasPorDia(
        datasFiltradasSemOrdem
      );
      const leiturasUnicas = datasFiltradas.sort((a, b) =>
        a.id > b.id ? 1 : -1
      );
      setDados({
        labels: leiturasUnicas.map((leitura) => leitura.data),
        datasets: [
          {
            label: "Temperaturas",
            data: leiturasUnicas.map((leitura) => leitura.temp),
            borderColor: "black",
            borderWidth: 2,
            backgroundColor: "blue",
            pointRadius: 0,
          },
        ],
      });
    } else if (diasDoGrafico === "7") {
      setBotao7DiasSelecionado(true);
      setBotao30DiasSelecionado(false);
      setBotao24(false);
      const datasFiltradasSemOrdem = leituraComDataFormatada.filter(
        (leitura) =>
          leitura.dataFormatada.getTime() > Date.now() - 7 * 24 * 60 * 60 * 1000
      ); //primeiro número é o número de dias a filtrar

      const datasFiltradasEmOrdem = datasFiltradasSemOrdem.sort((a, b) =>
          a.id > b.id ? 1 : -1
        );

      const filtrarPrimeiraUltimaLeituraPorDia = (leituras) => {
        const leiturasPorDia = {};

        leituras.map((leitura) => {
          const dataSemHora = new Date(leitura.dataFormatada);
          dataSemHora.setHours(0, 0, 0, 0);

          if (!leiturasPorDia[dataSemHora]) {
            // Se não houver nenhuma leitura para este dia, inicialize com um array vazio.
            leiturasPorDia[dataSemHora] = [];
          }

          // Adicione a leitura ao array correspondente ao dia.
          leiturasPorDia[dataSemHora].push(leitura);
        });

        // Agora, leiturasPorDia é um objeto onde as chaves são as datas sem hora e os valores são arrays de leituras para cada dia.
        // Vamos extrair a primeira e a última leitura de cada dia.
        const leiturasPorDiaPrimeiraUltima = Object.values(leiturasPorDia).map(
          (leiturasDoDia) => {
            const primeiraLeitura = leiturasDoDia.reduce((anterior, atual) =>
              anterior.dataFormatada < atual.dataFormatada ? anterior : atual
            );
            const ultimaLeitura = leiturasDoDia.reduce((anterior, atual) =>
              anterior.dataFormatada > atual.dataFormatada ? anterior : atual
            );
            return { primeira: primeiraLeitura, ultima: ultimaLeitura };
          }
        );

        return leiturasPorDiaPrimeiraUltima;
      };

      const datasFiltradas = filtrarPrimeiraUltimaLeituraPorDia(
        datasFiltradasEmOrdem
      );

     
      const labels = datasFiltradas.map(({ primeira, ultima }) => [
        primeira.data,
        ultima.data,
      ]);
      const dataVolumes = datasFiltradas.map(({ primeira, ultima }) => [
        primeira.temp,
        ultima.temp,
      ]);

      // Concatena os arrays de datas e porcentagens de volume para formar os dados do gráfico
      const labelsConcatenadas = [].concat(...labels);
      const dataVolumesConcatenados = [].concat(...dataVolumes);

      setDados({
        labels: labelsConcatenadas,
        datasets: [
          {
            label: "Temperaturas",
            data: dataVolumesConcatenados,
            borderColor: "black",
            borderWidth: 2,
            backgroundColor: "blue",
            pointRadius: 0,
          },
        ],
      });
    }

    setTipoDados("temperatura");
  };

  const mudarPraVolume = () => {
    setBotaoTDS(false);
    setBotaoTemperatura(false);
    setBotaoVolume(true);
    if (diasDoGrafico === "1") {
      setBotao7DiasSelecionado(false);
      setBotao30DiasSelecionado(false);
      setBotao24(true);
      const filtrarUltimasLeiturasPorHora = (leituras) => {
        const leiturasPorHora = {};
        const ultimas24Horas = Date.now() - 24 * 60 * 60 * 1000;

        leituras.map((leitura) => {
          // Obtenha a hora arredondada da leitura
          const horas = new Date(leitura.dataFormatada);
          const horaArredondada = new Date(
            horas.getFullYear(),
            horas.getMonth(),
            horas.getDate(),
            horas.getHours(),
            0, // Zera minutos
            0, // Zera segundos
            0 // Zera milissegundos
          );

          // Se a leitura está dentro das últimas 24 horas
          if (leitura.dataFormatada >= ultimas24Horas) {
            // Se não há leitura anterior para essa hora OU
            // a diferença de tempo (em horas) entre a leitura atual e a leitura anterior é maior que 1 hora
            if (
              !leiturasPorHora[horaArredondada] ||
              Math.abs(
                (leitura.dataFormatada -
                  leiturasPorHora[horaArredondada].dataFormatada) /
                  (60 * 60 * 1000)
              ) >= 1
            ) {
              leiturasPorHora[horaArredondada] = leitura;
            }
          }
        });

        return Object.values(leiturasPorHora);
      };
      const datasFiltradasSemOrdem = leituraComDataFormatada.filter(
        (leitura) =>
          leitura.dataFormatada.getTime() >= Date.now() - 24 * 60 * 60 * 1000
      );

      const datasFiltradas = filtrarUltimasLeiturasPorHora(
        datasFiltradasSemOrdem
      );
      const leiturasUnicas = datasFiltradas.sort((a, b) =>
        a.id > b.id ? 1 : -1
      );
      setDados({
        labels: leiturasUnicas.map((leitura) => leitura.data),
        datasets: [
          {
            label: "Volumes",
            data: leiturasUnicas.map((leitura) => leitura.porcentagemVolume),
            borderColor: "black",
            borderWidth: 2,
            backgroundColor: "blue",
            pointRadius: 0,
          },
        ],
      });
    } else if (diasDoGrafico === "30") {
      setBotao7DiasSelecionado(false);
      setBotao30DiasSelecionado(true);
      setBotao24(false);

      const datasFiltradasSemOrdem = leituraComDataFormatada.filter(
        (leitura) =>
          leitura.dataFormatada.getTime() >
          Date.now() - 30 * 24 * 60 * 60 * 1000
      );
      const filtrarUltimasLeiturasPorDia = (leituras) => {
        const leiturasPorDia = {};

        leituras.map((leitura) => {
          const dataSemHora = new Date(leitura.dataFormatada);
          dataSemHora.setHours(0, 0, 0, 0);

          if (
            !leiturasPorDia[dataSemHora] ||
            leitura.dataFormatada > leiturasPorDia[dataSemHora].dataFormatada
          ) {
            leiturasPorDia[dataSemHora] = leitura;
          }
        });

        return Object.values(leiturasPorDia);
      };

      const datasFiltradas = filtrarUltimasLeiturasPorDia(
        datasFiltradasSemOrdem
      );
      const leiturasUnicas = datasFiltradas.sort((a, b) =>
        a.id > b.id ? 1 : -1
      );
      setDados({
        labels: leiturasUnicas.map((leitura) => leitura.data),
        datasets: [
          {
            label: "Volumes",
            data: leiturasUnicas.map((leitura) => leitura.porcentagemVolume),
            borderColor: "black",
            borderWidth: 2,
            backgroundColor: "blue",
            pointRadius: 0,
          },
        ],
      });
    } else if (diasDoGrafico === "7") {
      setBotao7DiasSelecionado(true);
      setBotao30DiasSelecionado(false);
      setBotao24(false);

      const datasFiltradasSemOrdem = leituraComDataFormatada.filter(
        (leitura) =>
          leitura.dataFormatada.getTime() > Date.now() - 7 * 24 * 60 * 60 * 1000
      ); //primeiro número é o número de dias a filtrar

      const datasFiltradasEmOrdem = datasFiltradasSemOrdem.sort((a, b) =>
      a.id > b.id ? 1 : -1
    );

      const filtrarPrimeiraUltimaLeituraPorDia = (leituras) => {
        const leiturasPorDia = {};

        leituras.map((leitura) => {
          const dataSemHora = new Date(leitura.dataFormatada);
          dataSemHora.setHours(0, 0, 0, 0);

          if (!leiturasPorDia[dataSemHora]) {
            // Se não houver nenhuma leitura para este dia, inicialize com um array vazio.
            leiturasPorDia[dataSemHora] = [];
          }

          // Adicione a leitura ao array correspondente ao dia.
          leiturasPorDia[dataSemHora].push(leitura);
        });

        // Agora, leiturasPorDia é um objeto onde as chaves são as datas sem hora e os valores são arrays de leituras para cada dia.
        // Vamos extrair a primeira e a última leitura de cada dia.
        const leiturasPorDiaPrimeiraUltima = Object.values(leiturasPorDia).map(
          (leiturasDoDia) => {
            const primeiraLeitura = leiturasDoDia.reduce((anterior, atual) =>
              anterior.dataFormatada < atual.dataFormatada ? anterior : atual
            );
            const ultimaLeitura = leiturasDoDia.reduce((anterior, atual) =>
              anterior.dataFormatada > atual.dataFormatada ? anterior : atual
            );
            return { primeira: primeiraLeitura, ultima: ultimaLeitura };
          }
        );

        return leiturasPorDiaPrimeiraUltima;
      };

      const datasFiltradas = filtrarPrimeiraUltimaLeituraPorDia(
        datasFiltradasEmOrdem
      );

  

      const labels = datasFiltradas.map(({ primeira, ultima }) => [
        primeira.data,
        ultima.data,
      ]);
      const dataVolumes = datasFiltradas.map(({ primeira, ultima }) => [
        primeira.porcentagemVolume,
        ultima.porcentagemVolume,
      ]);

      // Concatena os arrays de datas e porcentagens de volume para formar os dados do gráfico
      const labelsConcatenadas = [].concat(...labels);
      const dataVolumesConcatenados = [].concat(...dataVolumes);

      setDados({
        labels: labelsConcatenadas,
        datasets: [
          {
            label: "Volumes",
            data: dataVolumesConcatenados,
            borderColor: "black",
            borderWidth: 2,
            backgroundColor: "blue",
            pointRadius: 0,
          },
        ],
      });
    }

    setTipoDados("volume");
  };

  const mudarPraTDS = () => {
    setBotaoTDS(true);
    setBotaoTemperatura(false);
    setBotaoVolume(false);
    if (diasDoGrafico === "1") {
      setBotao7DiasSelecionado(false);
      setBotao30DiasSelecionado(false);
      setBotao24(true);

      const filtrarUltimasLeiturasPorHora = (leituras) => {
        const leiturasPorHora = {};
        const ultimas24Horas = Date.now() - 24 * 60 * 60 * 1000;

        leituras.map((leitura) => {
          // Obtenha a hora arredondada da leitura
          const horas = new Date(leitura.dataFormatada);
          const horaArredondada = new Date(
            horas.getFullYear(),
            horas.getMonth(),
            horas.getDate(),
            horas.getHours(),
            0, // Zera minutos
            0, // Zera segundos
            0 // Zera milissegundos
          );

          // Se a leitura está dentro das últimas 24 horas
          if (leitura.dataFormatada >= ultimas24Horas) {
            // Se não há leitura anterior para essa hora OU
            // a diferença de tempo (em horas) entre a leitura atual e a leitura anterior é maior que 1 hora
            if (
              !leiturasPorHora[horaArredondada] ||
              Math.abs(
                (leitura.dataFormatada -
                  leiturasPorHora[horaArredondada].dataFormatada) /
                  (60 * 60 * 1000)
              ) >= 1
            ) {
              leiturasPorHora[horaArredondada] = leitura;
            }
          }
        });

        return Object.values(leiturasPorHora);
      };
      const datasFiltradasSemOrdem = leituraComDataFormatada.filter(
        (leitura) =>
          leitura.dataFormatada.getTime() >= Date.now() - 24 * 60 * 60 * 1000
      );

      const datasFiltradas = filtrarUltimasLeiturasPorHora(
        datasFiltradasSemOrdem
      );
      const leiturasUnicas = datasFiltradas.sort((a, b) =>
        a.id > b.id ? 1 : -1
      );

      setDados({
        labels: leiturasUnicas.map((leitura) => leitura.data),
        datasets: [
          {
            label: "TDS",
            data: leiturasUnicas.map((leitura) => leitura.tds),
            borderColor: "black",
            borderWidth: 2,
            backgroundColor: "blue",
            pointRadius: 0,
          },
        ],
      });
    } else if (diasDoGrafico === "30") {
      setBotao7DiasSelecionado(false);
      setBotao30DiasSelecionado(true);
      setBotao24(false);
      const datasFiltradasSemOrdem = leituraComDataFormatada.filter(
        (leitura) =>
          leitura.dataFormatada.getTime() >
          Date.now() - 30 * 24 * 60 * 60 * 1000
      ); //primeiro número é o número de dias a filtrar

      const filtrarUltimasLeiturasPorDia = (leituras) => {
        const leiturasPorDia = {};

        leituras.map((leitura) => {
          const dataSemHora = new Date(leitura.dataFormatada);
          dataSemHora.setHours(0, 0, 0, 0);

          if (
            !leiturasPorDia[dataSemHora] ||
            leitura.dataFormatada > leiturasPorDia[dataSemHora].dataFormatada
          ) {
            leiturasPorDia[dataSemHora] = leitura;
          }
        });

        return Object.values(leiturasPorDia);
      };

      const datasFiltradas = filtrarUltimasLeiturasPorDia(
        datasFiltradasSemOrdem
      );
      const leiturasUnicas = datasFiltradas.sort((a, b) =>
        a.id > b.id ? 1 : -1
      );
      setDados({
        labels: leiturasUnicas.map((leitura) => leitura.data),
        datasets: [
          {
            label: "TDS",
            data: leiturasUnicas.map((leitura) => leitura.tds),
            borderColor: "black",
            borderWidth: 2,
            backgroundColor: "blue",
            pointRadius: 0,
          },
        ],
      });
    } else if (diasDoGrafico === "7") {
      setBotao7DiasSelecionado(true);
      setBotao30DiasSelecionado(false);
      setBotao24(false);
      const datasFiltradasSemOrdem = leituraComDataFormatada.filter(
        (leitura) =>
          leitura.dataFormatada.getTime() > Date.now() - 7 * 24 * 60 * 60 * 1000
      ); //primeiro número é o número de dias a filtrar

const datasFiltradasEmOrdem = datasFiltradasSemOrdem.sort((a, b) =>
          a.id > b.id ? 1 : -1
        );

      const filtrarPrimeiraUltimaLeituraPorDia = (leituras) => {
        const leiturasPorDia = {};

        leituras.map((leitura) => {
          const dataSemHora = new Date(leitura.dataFormatada);
          dataSemHora.setHours(0, 0, 0, 0);

          if (!leiturasPorDia[dataSemHora]) {
            // Se não houver nenhuma leitura para este dia, inicialize com um array vazio.
            leiturasPorDia[dataSemHora] = [];
          }

          // Adicione a leitura ao array correspondente ao dia.
          leiturasPorDia[dataSemHora].push(leitura);
        });

        // Agora, leiturasPorDia é um objeto onde as chaves são as datas sem hora e os valores são arrays de leituras para cada dia.
        // Vamos extrair a primeira e a última leitura de cada dia.
        const leiturasPorDiaPrimeiraUltima = Object.values(leiturasPorDia).map(
          (leiturasDoDia) => {
            const primeiraLeitura = leiturasDoDia.reduce((anterior, atual) =>
              anterior.dataFormatada < atual.dataFormatada ? anterior : atual
            );
            const ultimaLeitura = leiturasDoDia.reduce((anterior, atual) =>
              anterior.dataFormatada > atual.dataFormatada ? anterior : atual
            );
            return { primeira: primeiraLeitura, ultima: ultimaLeitura };
          }
        );

        return leiturasPorDiaPrimeiraUltima;
      };

      const datasFiltradas = filtrarPrimeiraUltimaLeituraPorDia(
        datasFiltradasEmOrdem
      );

      // const datasFiltradas = leiturasUnicas.sort((a, b) =>
      //   a.id > b.id ? 1 : -1
      // );

      const labels = datasFiltradas.map(({ primeira, ultima }) => [
        primeira.data,
        ultima.data,
      ]);
      const dataVolumes = datasFiltradas.map(({ primeira, ultima }) => [
        primeira.tds,
        ultima.tds,
      ]);

      // Concatena os arrays de datas e porcentagens de volume para formar os dados do gráfico
      const labelsConcatenadas = [].concat(...labels);
      const dataVolumesConcatenados = [].concat(...dataVolumes);

      setDados({
        labels: labelsConcatenadas,
        datasets: [
          {
            label: "TDS",
            data: dataVolumesConcatenados,
            borderColor: "black",
            borderWidth: 2,
            backgroundColor: "blue",
            pointRadius: 0,
          },
        ],
      });
    }

    setTipoDados("tds");
  };

  const graficoLeiturasRelatorio = () => {
    setBotao30DiasSelecionado(true);
    setBotao7DiasSelecionado(false);
    setBotao24(false);
    const datasFiltradasSemOrdem = leituraComDataFormatada.filter(
      (leitura) =>
        leitura.dataFormatada.getTime() > Date.now() - 30 * 24 * 60 * 60 * 1000
    ); //primeiro número é o número de dias a filtrar

    const filtrarUltimasLeiturasPorDia = (leituras) => {
      const leiturasPorDia = {};

      leituras.map((leitura) => {
        const dataSemHora = new Date(leitura.dataFormatada);
        dataSemHora.setHours(0, 0, 0, 0);

        if (
          !leiturasPorDia[dataSemHora] ||
          leitura.dataFormatada > leiturasPorDia[dataSemHora].dataFormatada
        ) {
          leiturasPorDia[dataSemHora] = leitura;
        }
      });

      return Object.values(leiturasPorDia);
    };

    const datasFiltradas = filtrarUltimasLeiturasPorDia(datasFiltradasSemOrdem);
    const leiturasUnicas = datasFiltradas.sort((a, b) =>
      a.id > b.id ? 1 : -1
    );

    if (tipoDados === "volume") {
      setDados({
        labels: leiturasUnicas.map((leitura) => leitura.data),
        datasets: [
          {
            label: "Volumes",
            data: leiturasUnicas.map((leitura) => leitura.porcentagemVolume),
            borderColor: "black",
            borderWidth: 2,
            backgroundColor: "blue",
            pointRadius: 0,
          },
        ],
      });
    } else if (tipoDados === "temperatura") {
      setDados({
        labels: leiturasUnicas.map((leitura) => leitura.data),
        datasets: [
          {
            label: "Temperaturas",
            data: leiturasUnicas.map((leitura) => leitura.temp),
            borderColor: "black",
            borderWidth: 2,
            backgroundColor: "blue",
            pointRadius: 0,
          },
        ],
      });
    } else if (tipoDados === "tds") {
      setDados({
        labels: leiturasUnicas.map((leitura) => leitura.data),
        datasets: [
          {
            label: "TDS",
            data: leiturasUnicas.map((leitura) => leitura.tds),
            borderColor: "black",
            borderWidth: 2,
            backgroundColor: "blue",
            pointRadius: 0,
          },
        ],
      });
    }
    setDiasDoGrafico("30");
  };

  const graficoSeteDias = () => {
    setBotao7DiasSelecionado(true);
    setBotao30DiasSelecionado(false);
    setBotao24(false);
    const datasFiltradasSemOrdem = leituraComDataFormatada.filter(
      (leitura) =>
        leitura.dataFormatada.getTime() > Date.now() - 7 * 24 * 60 * 60 * 1000
    ); //primeiro número é o número de dias a filtrar

     const datasFiltradasEmOrdem = datasFiltradasSemOrdem.sort((a, b) =>
          a.id > b.id ? 1 : -1
        );


    const filtrarPrimeiraUltimaLeituraPorDia = (leituras) => {
      const leiturasPorDia = {};

      leituras.map((leitura) => {
        const dataSemHora = new Date(leitura.dataFormatada);
        dataSemHora.setHours(0, 0, 0, 0);

        if (!leiturasPorDia[dataSemHora]) {
          // Se não houver nenhuma leitura para este dia, inicialize com um array vazio.
          leiturasPorDia[dataSemHora] = [];
        }

        // Adicione a leitura ao array correspondente ao dia.
        leiturasPorDia[dataSemHora].push(leitura);
      });

      // Agora, leiturasPorDia é um objeto onde as chaves são as datas sem hora e os valores são arrays de leituras para cada dia.
      // Vamos extrair a primeira e a última leitura de cada dia.
      const leiturasPorDiaPrimeiraUltima = Object.values(leiturasPorDia).map(
        (leiturasDoDia) => {
          const primeiraLeitura = leiturasDoDia.reduce((anterior, atual) =>
            anterior.dataFormatada < atual.dataFormatada ? anterior : atual
          );
          const ultimaLeitura = leiturasDoDia.reduce((anterior, atual) =>
            anterior.dataFormatada > atual.dataFormatada ? anterior : atual
          );
          return { primeira: primeiraLeitura, ultima: ultimaLeitura };
        }
      );

      return leiturasPorDiaPrimeiraUltima;
    };

    const datasFiltradas = filtrarPrimeiraUltimaLeituraPorDia(
      datasFiltradasEmOrdem
    );



    // const datasFiltradas = leiturasUnicas.sort((a, b) =>
    //       a.id > b.id ? 1 : -1
    //     );

    if (tipoDados === "volume") {
      const labels = datasFiltradas.map(({ primeira, ultima }) => [
        primeira.data,
        ultima.data,
      ]);
      const dataVolumes = datasFiltradas.map(({ primeira, ultima }) => [
        primeira.porcentagemVolume,
        ultima.porcentagemVolume,
      ]);

      // Concatena os arrays de datas e porcentagens de volume para formar os dados do gráfico
      const labelsConcatenadas = [].concat(...labels);
      const dataVolumesConcatenados = [].concat(...dataVolumes);

      setDados({
        labels: labelsConcatenadas,
        datasets: [
          {
            label: "Volumes",
            data: dataVolumesConcatenados,
            borderColor: "black",
            borderWidth: 2,
            backgroundColor: "blue",
            pointRadius: 0,
          },
        ],
      });
    } else if (tipoDados === "temperatura") {
      const labels = datasFiltradas.map(({ primeira, ultima }) => [
        primeira.data,
        ultima.data,
      ]);
      const dataVolumes = datasFiltradas.map(({ primeira, ultima }) => [
        primeira.temp,
        ultima.temp,
      ]);

      // Concatena os arrays de datas e porcentagens de volume para formar os dados do gráfico
      const labelsConcatenadas = [].concat(...labels);
      const dataVolumesConcatenados = [].concat(...dataVolumes);

      setDados({
        labels: labelsConcatenadas,
        datasets: [
          {
            label: "Temperaturas",
            data: dataVolumesConcatenados,
            borderColor: "black",
            borderWidth: 2,
            backgroundColor: "blue",
            pointRadius: 0,
          },
        ],
      });
    } else if (tipoDados === "tds") {
      const labels = datasFiltradas.map(({ primeira, ultima }) => [
        primeira.data,
        ultima.data,
      ]);
      const dataVolumes = datasFiltradas.map(({ primeira, ultima }) => [
        primeira.tds,
        ultima.tds,
      ]);

      // Concatena os arrays de datas e porcentagens de volume para formar os dados do gráfico
      const labelsConcatenadas = [].concat(...labels);
      const dataVolumesConcatenados = [].concat(...dataVolumes);

      setDados({
        labels: labelsConcatenadas,
        datasets: [
          {
            label: "TDS",
            data: dataVolumesConcatenados,
            borderColor: "black",
            borderWidth: 2,
            backgroundColor: "blue",
            pointRadius: 0,
          },
        ],
      });
    }
    setDiasDoGrafico("7");
  };

  const graficoUmDia = () => {
    setBotao7DiasSelecionado(false);
    setBotao30DiasSelecionado(false);
    setBotao24(true);

    const filtrarUltimasLeiturasPorHora = (leituras) => {
      const leiturasPorHora = {};
      const ultimas24Horas = Date.now() - 24 * 60 * 60 * 1000;

      leituras.map((leitura) => {
        // Obtenha a hora arredondada da leitura
        const horas = new Date(leitura.dataFormatada);
        const horaArredondada = new Date(
          horas.getFullYear(),
          horas.getMonth(),
          horas.getDate(),
          horas.getHours(),
          0, // Zera minutos
          0, // Zera segundos
          0 // Zera milissegundos
        );

        // Se a leitura está dentro das últimas 24 horas
        if (leitura.dataFormatada >= ultimas24Horas) {
          // Se não há leitura anterior para essa hora OU
          // a diferença de tempo (em horas) entre a leitura atual e a leitura anterior é maior que 1 hora
          if (
            !leiturasPorHora[horaArredondada] ||
            Math.abs(
              (leitura.dataFormatada -
                leiturasPorHora[horaArredondada].dataFormatada) /
                (60 * 60 * 1000)
            ) >= 1
          ) {
            leiturasPorHora[horaArredondada] = leitura;
          }
        }
      });

      return Object.values(leiturasPorHora);
    };
    const datasFiltradasSemOrdem = leituraComDataFormatada.filter(
      (leitura) =>
        leitura.dataFormatada.getTime() >= Date.now() - 24 * 60 * 60 * 1000
    );

    const datasFiltradas = filtrarUltimasLeiturasPorHora(
      datasFiltradasSemOrdem
    );
    const leiturasUnicas = datasFiltradas.sort((a, b) =>
      a.id > b.id ? 1 : -1
    );

    if (tipoDados === "volume") {
      setDados({
        labels: leiturasUnicas.map((leitura) => leitura.data),
        datasets: [
          {
            label: "Volumes",
            data: leiturasUnicas.map((leitura) => leitura.porcentagemVolume),
            borderColor: "black",
            borderWidth: 2,
            backgroundColor: "blue",
            pointRadius: 0,
          },
        ],
      });
    } else if (tipoDados === "temperatura") {
      setDados({
        labels: leiturasUnicas.map((leitura) => leitura.data),
        datasets: [
          {
            label: "Temperaturas",
            data: leiturasUnicas.map((leitura) => leitura.temp),
            borderColor: "black",
            borderWidth: 2,
            backgroundColor: "blue",
            pointRadius: 0,
          },
        ],
      });
    } else if (tipoDados === "tds") {
      setDados({
        labels: leiturasUnicas.map((leitura) => leitura.data),
        datasets: [
          {
            label: "TDS",
            data: leiturasUnicas.map((leitura) => leitura.tds),
            borderColor: "black",
            borderWidth: 2,
            backgroundColor: "blue",
            pointRadius: 0,
          },
        ],
      });
    }
    setDiasDoGrafico("1");
  };

  useEffect(() => {
    getDatas();
    loadSensor();
  }, []);

  return (
    <>
      <Header />
      <div className="visualizarBox">
        <div className="container containerVisualizar">
          {unicoSensor ? (
            <div className="infoBox" style={{ height: "20%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <h3 style={{ position: "relative" }}>GRÁFICOS</h3>
              </div>

              <p>
                Id do módulo: <strong>{unicoSensor.id}</strong>{" "}
              </p>

              <p>
                Nome do módulo: <strong>{unicoSensor.nome}</strong>
              </p>

              <Link className="botao" to={`/modulos/${unicoSensor.id}`}>
                Voltar
              </Link>
            </div>
          ) : null}
          <div>
            {loading ? (
              <>
                <div className="infoBoxLeitura5">
                  <h3 style={{ textAlign: "center" }}>Carregando...</h3>{" "}
                </div>{" "}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingBottom: "5%",
                }}
              >
                <div
                  className="grafico"
                  style={{
                    justifyContent: "center",
                    borderRadius: "10px",
                    backgroundColor: "white",
                    textAlign: "center",
                    margin: 20,
                  }}
                >
                  {graphsIsLoaded ? (
                    <>
                      <Charts chartData={dados} />{" "}
                    </>
                  ) : null}

                  <div className="botoesGrafico">
                    <div className="tipoGrafico">
                      <button
                        className={
                          botaoTemperatura ? "botaoSelecionado" : "botao"
                        }
                        style={{ width: "100%" }}
                        onClick={mudarPraTemperatura}
                      >
                        {" "}
                        Temperatura{" "}
                      </button>
                      <button
                        className={botaoVolume ? "botaoSelecionado" : "botao"}
                        style={{ width: "100%" }}
                        onClick={mudarPraVolume}
                      >
                        {" "}
                        Volume{" "}
                      </button>
                      <button
                        className={botaoTDS ? "botaoSelecionado" : "botao"}
                        style={{ width: "100%" }}
                        onClick={mudarPraTDS}
                      >
                        {" "}
                        TDS{" "}
                      </button>
                    </div>
                    <div className="tempoGrafico">
                      <button
                        className={
                          botao30DiasSelecionado ? "botaoSelecionado" : "botao"
                        }
                        style={{ width: "100%" }}
                        onClick={graficoLeiturasRelatorio}
                      >
                        {" "}
                        30 dias{" "}
                      </button>
                      <button
                        className={
                          botao7DiasSelecionado ? "botaoSelecionado" : "botao"
                        }
                        style={{ width: "100%" }}
                        onClick={graficoSeteDias}
                      >
                        {" "}
                        7 dias{" "}
                      </button>
                      <button
                        className={botao24 ? "botaoSelecionado" : "botao"}
                        style={{ width: "100%" }}
                        onClick={graficoUmDia}
                      >
                        {" "}
                        24 horas{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
