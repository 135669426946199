import { useContext} from "react";

import { TanqueContext } from "../../providers/TanquesContext";
import { useState } from "react";
import { useForm } from "react-hook-form";

import { ClientsContext } from "../../providers/ClientesContext";
import { Link } from "react-router-dom";

import { AuthContext } from "../AuthContext";


export const SensoresInfoCardPainelCliente = ({
  cliente
}) => {


  return (
    <>
      <li className="itemCardAdminPainel">
                <p>Nome: <strong>{cliente.nome}</strong></p>

         {cliente.cnpj || cliente.cpf ? (
          <div>
            {cliente.cnpj && <p>CNPJ: <strong>{cliente.cnpj}</strong></p>}
            {cliente.cpf && <p>CPF: <strong>{cliente.cpf}</strong></p>}
          </div>
        ) : (
          <p style={{fontSize: "0.9rem"}}>CNPJ/CPF ainda não cadastrados.</p>
        )}

        <div>
          <div style={{ display: "flex" }}>
            <Link className="botao" to={`/clientes/${cliente.id}`}>
            
              Visualizar cliente
            </Link>
            <Link className="botao" to={`/cliente-modulos/${cliente.id}`}>
         
              Visualizar módulos de cliente
            </Link>
          </div>
          <div></div>
        </div>
      </li>
    </>
  );
};
