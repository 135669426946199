import React from "react";
import "./ClienteInfo.css";

import { Link } from "react-router-dom";


const ClienteInfo = ({
  clientesComModais,
  cliente,
  deleteCliente,
  setClientesComModais,
  confirmRemoveCliente,
}) => {
  return (
    <>
      <li className="itemCardAdmin ">
        <div style={{display:"flex", flexDirection:"column"}}className="client">
          
          <p className=""> Nome: <strong>{cliente.nome}</strong></p>
        <p className="">Username: <strong>{cliente.usuario?.username}</strong></p>
        </div>
        <div style={{display:"flex", flexDirection:"column"}}>
<p>E-mail: <strong>{cliente.email}</strong></p>
        {cliente.isActive == true  && <p className="">Cliente <strong>Ativo</strong></p>}
        {cliente.isActive == false && <p className="">Cliente <strong>Inativo</strong></p>}
        </div>
        {cliente.cnpj || cliente.cpf ? (
          <div>
            {cliente.cnpj && <p>CNPJ: <strong>{cliente.cnpj}</strong></p>}
            {cliente.cpf && <p>CPF: <strong>{cliente.cpf}</strong></p>}
          </div>
        ) : (
          <p style={{fontSize: "0.9rem"}}><strong>CNPJ/CPF ainda não cadastrados.</strong></p>
        )}
        <div className="botoesClienteInfo">
          <div style={{ display: "flex" }} className="clienteBotoes">
            <Link className="botao" to={`/clientes/${cliente.id}`}>
              Visualizar cliente
            </Link>
            <Link className="botao" to={`/cliente-modulos/${cliente.id}`}>
              Visualizar módulos de cliente
            </Link>
          </div>
          <button
            className="botao-remove"
            onClick={() => confirmRemoveCliente(cliente.id)}
          >
            Remover
          </button>
        </div>
      </li>
      {clientesComModais[cliente.id] ? (
        <div className="confirmation-modal">
          <div className="confirmation-box">
            <p>Tem certeza de que quer apagar o cliente {cliente.nome}?</p>

            <div className="confirmation-buttons">
              <button
                className="confirm-button"
                onClick={() => deleteCliente(cliente.id)}
              >
                Confirmar
              </button>
              <button
                className="cancel-button"
                onClick={() => setClientesComModais(false)}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ClienteInfo;
