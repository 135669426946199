import { useNavigate, useParams } from "react-router-dom";
import "./PagIndividual.css";
import { useContext, useEffect, useState } from "react";
import { api } from "../../services/api";
import { Header } from "../Header/Header";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { CadaClienteFormSchema } from "./ValidacaoZod/CadaClienteFormSchema";

import { ClientsContext } from "../../providers/ClientesContext";

export const PagEditarCadaCliente = () => {
  const [unicoCliente, setUnicoCliente] = useState(null);
  const { idParam } = useParams();
  const { clientes, setClientes } = useContext(ClientsContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(CadaClienteFormSchema),
  });
  const navigate = useNavigate();

  useEffect(() => {
    const loadCliente = async () => {
      const token = JSON.parse(localStorage.getItem("token"));

      try {
        const { data } = await api.get(`/clientes/${idParam}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUnicoCliente(data);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          // Se o erro for 403 (Forbidden)      
          navigate('/')
        } else {
          // Se for um erro diferente de 403, faça o log do erro
          console.log(error);} 
      }
    };
    loadCliente();
  }, [idParam]);

  const editClient = async (formData) => {
    const token = JSON.parse(localStorage.getItem("token"));

    try {
      const { data } = await api.put(`/clientes/${idParam}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUnicoCliente(data);

      const updatedClientes = clientes.map((cliente) =>
        cliente.id === idParam ? { ...cliente, ...data } : cliente
      );

      setClientes(updatedClientes);

      toast.success("Cliente editado com sucesso");
      navigate(`/clientes/${unicoCliente.id}`);
    } catch (error) {
      console.log(error);
      toast.error("Ops! Algo deu errado.");
    }
  };

  
  const submit = (formData) => {
 
    editClient(formData);
  };


  return (
    <>
      <Header />
      <div className="flexBox">
        <div className="containerPagIndividual">
          <div>
            <h3>Editar cliente</h3>
          </div>
          {unicoCliente ? (
            <>
              <form className="formEditar" onSubmit={handleSubmit(submit)}>
                <input
                  className="inputEditar"
                  type="hidden"
                  {...register("id")}
                  defaultValue={parseInt(unicoCliente.id)}
                />
                <label>
                  {" "}
                  Cliente Id
                  <input
                    className="inputEditarSemBorda"
                    disabled
                    {...register("id")}
                    defaultValue={parseInt(unicoCliente.id)}
                  />
                </label>

                <label>
                  Nome do cliente
                  <input
                    className="inputEditar"
                    {...register("nome")}
                    defaultValue={unicoCliente.nome}
                  />
                </label>
                {errors.nome ? <p>{errors.nome.message}</p> : null}
                <label>
                  Usuário
                  <input
                    className="inputEditar"
                    {...register("username")}
                    defaultValue={unicoCliente.usuario.username}
                  />
                </label>

                <label>
                  Senha
                  <input className="inputEditar" {...register("password")} />
                </label>
                {errors.password ? <p>{errors.password.message}</p> : null}

               

    
                <label>Status:</label>
               
              <label>
                Ativo
                <input
             {...register("isActive")}
             type="radio"
             defaultChecked={unicoCliente.isActive === true}
             value = {true}

                />
              </label>
              <label>
                Inativo
                <input
                {...register("isActive")}
             type="radio"
             defaultChecked={unicoCliente.isActive === false}
             value= {false}
         
                />
              </label>
           
              <label>
                  E-mail
                  <input
                    className="inputEditar"
                    {...register("email")}
                    defaultValue={unicoCliente.email}
                  />
                </label>
        
        
  
                <label>
                  CPF do cliente
                  <input
                    className="inputEditar"
                    {...register("cpf")}
                    defaultValue={unicoCliente.cpf}
                  />
                </label>

                <label>
                  CNPJ do cliente
                  <input
                    className="inputEditar"
                    {...register("cnpj")}
                    defaultValue={unicoCliente.cnpj}
                  />
                </label>
                <button className="botao" type="submit">
                  Editar cliente
                </button>
              </form>
            </>
          ) : null}
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );

}
