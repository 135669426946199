// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.botaoSelecionado {
  text-decoration: none;
  background-color: red;
  color: white;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.9rem;
  }

  canvas {
    width: 50%;
    height: 100%;
  }

  .grafico{
    width: 100%;
  }
  
  /* Ajustes para dispositivos móveis */
  @media (min-width: 730px) {
    canvas {
      width: 50%;
      height: 300px; /* Ajuste a altura conforme necessário */
    }

    .grafico{
      width: 700px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Graficos/styles/graficos.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,qBAAqB;EACrB,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,iBAAiB;EACjB;;EAEA;IACE,UAAU;IACV,YAAY;EACd;;EAEA;IACE,WAAW;EACb;;EAEA,qCAAqC;EACrC;IACE;MACE,UAAU;MACV,aAAa,EAAE,wCAAwC;IACzD;;IAEA;MACE,YAAY;IACd;EACF","sourcesContent":[".botaoSelecionado {\r\n  text-decoration: none;\r\n  background-color: red;\r\n  color: white;\r\n  margin: 10px;\r\n  padding: 10px;\r\n  border-radius: 10px;\r\n  height: 30px;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  text-align: center;\r\n  font-size: 0.9rem;\r\n  }\r\n\r\n  canvas {\r\n    width: 50%;\r\n    height: 100%;\r\n  }\r\n\r\n  .grafico{\r\n    width: 100%;\r\n  }\r\n  \r\n  /* Ajustes para dispositivos móveis */\r\n  @media (min-width: 730px) {\r\n    canvas {\r\n      width: 50%;\r\n      height: 300px; /* Ajuste a altura conforme necessário */\r\n    }\r\n\r\n    .grafico{\r\n      width: 700px;\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
