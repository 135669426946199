import "./Footer.css"

export const Footer = () => {
  return (
    <footer>
    <div className="spacing"></div>
   <div className="footer">

   </div>
      </footer>
    
  );
};
