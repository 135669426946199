import { Link, useNavigate, useParams } from "react-router-dom";
import "./PagIndividual.css";
import { useEffect, useState } from "react";
import { api } from "../../services/api";

import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";

import { useForm } from "react-hook-form";
import { MdSearch } from "react-icons/md";
import { SensorUserCardAdmin } from "./SensorUserCardAdmin";

export const PagCadaClienteSensores = () => {
  const [allSensoresCliente, setAllSensoresCliente] = useState(null);
  const [loading, setLoading] = useState(false);
  const { idParam } = useParams();
  const [searchSensor, setSearchSensor] = useState("");
  const [allSensoresList, setAllSensoresList] = useState([]);
  const [filteredSensores, setFilteredSensores] = useState([]);
  const navigate = useNavigate();
  const { register, handleSubmit, reset } = useForm();
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));

    const loadClienteSensores = async () => {
      setLoading(true);
      try {
        const { data } = await api.get(`/clientes/${idParam}/lista-sensores`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (data.length === 0) {
          setAllSensoresCliente(0);
        } else {
          setAllSensoresCliente(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          // Se o erro for 403 (Forbidden)
          navigate("/");
        } else {
          // Se for um erro diferente de 403, faça o log do erro
          console.log(error);
        }
      } finally {
        setLoading(false);
      }
    };
    loadClienteSensores();
  }, [idParam]);

  useEffect(() => {
    const filteredSensoresList = allSensoresList.filter(
      (sensor) =>
        sensor.nome.toLowerCase().includes(searchSensor.toLowerCase()) ||
        sensor.marca == searchSensor
    );

    setFilteredSensores(filteredSensoresList);
  }, [searchSensor, allSensoresList]);

  const submitSearch = (data) => {
    reset({ searchSensor: "" });
    setSearchSensor(searchSensor);
  };

  const sensoresList = searchSensor ? filteredSensores : allSensoresCliente;

  return (
    <>
      <Header />
      <div className="visualizarBox">
        {loading ? (
          <>
            <div className=" container ">
              <p
                style={{
                  position: "relative",
                  justifyContent: "center",
                  color: "white",
                  margin: "30px",
                  padding: "34px",
                }}
              >
                Carregando...
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="container containerVisualizar">
              {allSensoresCliente === null || !allSensoresCliente[0] ? (
                <>
                  <div style={{ height: "100vh", margin: "50px" }}>
                    <p>Cliente ainda não tem módulos cadastrados.</p>
                    <Link className="btn-sensor" to="/modulos">
                      Atribuir módulo a cliente
                    </Link>
                  </div>
                </>
              ) : (
                <div>
                  <h2 style={{ textAlign: "center" }}>
                    Módulos do cliente{" "}
                    <strong>{allSensoresCliente[0]?.cliente.nome}</strong>
                  </h2>

                  <p style={{ textAlign: "center" }}>
                    Username: {allSensoresCliente[0]?.cliente.usuario.username}
                  </p>
                  <p style={{ textAlign: "center" }}>
                    E-mail: {allSensoresCliente[0]?.cliente.email}
                  </p>
                  <p style={{ textAlign: "center" }}>
                    Status:{" "}
                    {allSensoresCliente[0]?.cliente.isActive
                      ? "Ativo"
                      : "Inativo"}
                  </p>
                  <div className="containerSearchUser">
                    <p>Buscar módulo por nome ou IP</p>
                    <form
                      onSubmit={handleSubmit(submitSearch)}
                      className="formSearch"
                    >
                      <input
                        type="text"
                        {...register("searchSensor")}
                        value={searchSensor}
                        placeholder="Digite IP ou nome do módulo"
                        onChange={(e) => setSearchSensor(e.target.value)}
                      />
                      <button type="submit" className="btnSearch">
                        <MdSearch size={21} />
                      </button>
                    </form>
                  </div>
                  <div className="container">
                    {sensoresList?.length === 0 ? (
                      <p
                        style={{
                          position: "relative",
                          justifyContent: "center",
                          color: "white",
                          margin: "30px",
                          padding: "34px",
                        }}
                      >
                        Nenhum resultado encontrado.
                      </p>
                    ) : (
                      <ul className="lista">
                        {sensoresList.map((sensor, index) => (
                          <SensorUserCardAdmin key={index} sensor={sensor} />
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>

    </>
  );
};
