import { useContext } from "react";
import "./SensoresInfo.css";
import { TanqueContext } from "../../providers/TanquesContext";
import { useState } from "react";
import { useForm } from "react-hook-form";

import { ClientsContext } from "../../providers/ClientesContext";
import { Link } from "react-router-dom";

import { AuthContext } from "../AuthContext";
import { SlPeople } from "react-icons/sl";
import { GiWaterTank } from "react-icons/gi";

import { MdOutlineSensors } from "react-icons/md";

export const SensoresInfo = ({
  sensor,


  deleteSensor,
  confirmRemoveSensor,

  sensoresComModais,
  setSensoresComModais,
}) => {


  return (
    <>
      <MdOutlineSensors size={35} />
      <li className="itemCardAdmin">
        <p>Id: <strong>{sensor.id}</strong></p>
        <p>Nome: <strong>{sensor.nome}</strong></p>
  

        <div>
        <p>
          {" "}
          <SlPeople size={18} /> Cliente:
        </p>
        {sensor.cliente ? (
          <>
            <div>
              <p><strong>{sensor.cliente.nome}</strong></p>{" "}
            </div>
          </>
        ) : (
          <p style={{color: "red"}} className="disponivel"> <strong>Sem cliente atribuído</strong></p>
        )}

        <p>
          <GiWaterTank className="tank" size={18} /> Tanque:
        </p>
        {sensor.tanque ? (
          <>
            <div>
              <p><strong>{sensor.tanque.nome}</strong></p>{" "}
            </div>
          </>
        ) : (
          <>
            <p style={{color: "red"}}className="disponivel"> <strong>Módulo sem tanque atribuído</strong></p>
          </>
        )}
        </div>

        <div>
          <div style={{display: "flex"}}>
            <Link className="botao" to={`/editar-modulos/${sensor.id}`}>
          
              Editar
            </Link>
            <Link className="botao" to={`/modulos/${sensor.id}`}>
           
              Visualizar
            </Link>
          </div>
          <div>
            <button
              className="botao-remove"
              onClick={() => confirmRemoveSensor(sensor.id)}
            >
              {/* <CiCircleRemove size={18}/> */}
              Remover
            </button>
          </div>
        </div>
      </li>

      {sensoresComModais[sensor.id] ? (
        <div className="confirmation-modal">
          <div className="confirmation-box">
            <p>Tem certeza de que deseja apagar o módulo {sensor.nome}?</p>
            <div className="confirmation-buttons">
              <button
                className="confirm-button"
                onClick={() => deleteSensor(sensor.id)}
              >
                Confirmar
              </button>
              <button
                className="cancel-button"
                onClick={() => {
                  setSensoresComModais(false);
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
