import React, { useEffect, useState } from "react";
import { api } from "../../services/api";
import "./Leituras.css";

const TodasLeituras = () => {
  const [leituras, setLeituras] = useState([]);

  useEffect(() => {
    const todasLeituras = async () => {
      try {
        const { data } = await api.get(`/leituras`);

        const leiturasRevertidas = data.reverse();
        setLeituras(leiturasRevertidas);
      } catch (error) {
        console.log(error);
      }
    };
    todasLeituras();
  }, [leituras]);

  return (
  
      <ul>
        {leituras.map((leitura, index) => ( 
          <li key={index} style={{ display: "inline-block" }}>
            <p className="color"> 
            {'{'} Data: {leitura.data},
              Altura: {leitura.altura},
              Altura corrigida: {leitura.alturaCorrigida},
              Volume:{ leitura.volume}, 
              Porcentagem Volume: {leitura.porcentagemVolume}, 
              Temperatura: {leitura.temp}, 
              Tds: {leitura.tds}, 
              Odômetro: {leitura.odometro ? `${Number(leitura.odometro)}` : "-"},
              Placa: {leitura.placa ? leitura.placa.placa : "-"},
              Motorista: {leitura.motorista ? leitura.motorista.nome : "-"},
              Id Motorista: {leitura.motorista ? leitura.motorista.id : "-"},
              Frentista: {leitura.frentista ? leitura.frentista.nome : "-"},
              Id Frentista: {leitura.frentista ? leitura.frentista.id : "-"},
              Totalizador: {leitura.totalizador ? `${leitura.totalizador}` : "-"},
              Abastecimento: {leitura.qntdAbastecimento ? `${leitura.qntdAbastecimento}` : "-"} {'}'}
            </p>
          </li>
        ))}
      </ul>

  );
};

export default TodasLeituras;
