
import { Link } from "react-router-dom";

export const SensorUserCard = ({ sensor }) => {
  return (
    <>
      <li key={sensor.id} className="itemCard">
        <div className="userCard"
        
        >
          {/* <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between",  }}> */}
            <p className="h3-user" style={{ fontSize: "0.9rem"}}  > Nome módulo: <strong>{sensor.nome}</strong></p>
            <p className="p-user" style={{ fontSize: "0.9rem"}} >Id: <strong>{sensor.id}</strong></p>
          {/* </div> */}
          <p className="p-user" style={{ fontSize: "0.9rem"}} >Tanque: <strong>{sensor.tanque?.nome}</strong></p>

          {sensor.ultimaLeitura ? (
            <>
              {/* <div style={{ display: "flex", gap: "50px", width: "100%"}}> */}
                <p style={{ fontSize: "0.9rem"}}  className="p-user">
                  Última leitura: <strong>{sensor.ultimaLeitura.data}</strong>
                </p>
                <p style={{ fontSize: "0.9rem"}} className="p-user">
                  Altura espaço aberto: <strong>{Number(sensor.ultimaLeitura.alturaCorrigida)} mm</strong>
                </p>
              {/* </div> */}
            </>
          ) : (
            <p>Nenhuma leitura cadastrada.</p>
          )}
          
          <Link to={`/user/dashboard/${sensor.id}`} className="botao btn">
            Visualizar leituras
          </Link>
        </div>
      </li>
    </>
  );
};
