import loading from "../../assets/Loading.svg";
import "./Loading.css";

export const Loading = () => {
  return (
    
    <div className="loadingBox">
      <img src={loading} alt="Carregando" />
      <p className="paragraph">Carregando...</p>
    </div>
  );
};
