import { z } from "zod";

export const VeiculoFormSchema = z.object({
  placa: z
    .string()
    .nonempty("A placa é obrigatória")
    .refine((value) => value.length === 7, {
      message: "A placa deve ter exatamente 7 caracteres",
    })
    .transform((value) => value.toUpperCase()),
  modelo: z.string().nonempty("O modelo é obrigatório"),
  odometro: z
  .string()
  .nonempty("O odômetro é obrigatório")
  .refine((value) => value.length === 8, {
    message: "A placa deve ter exatamente 8 caracteres",
  }),
});
