import { RxDashboard } from "react-icons/rx";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import "./Painel.css";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MdSearch } from "react-icons/md";
import { SensoresInfoCardPainel } from "./SensorInfoCardPainel";

import { SensoresContext } from "../../providers/SensoresContext";
import { api } from "../../services/api";
import { ClientsContext } from "../../providers/ClientesContext";
import { SensoresInfoCardPainelCliente } from "./SensoresInfoCardPainelTanque";
import { Loading } from "../Loading/Loading";

export const Painel1 = () => {
  const { register, handleSubmit, reset } = useForm();
  const [searchSensor, setSearchSensor] = useState("");
  const [searchSensorCliente, setSearchSensorCliente] = useState("");
  const [tanqueExists, setTanqueExists] = useState(false);
  const { sensores, setSensores } = useContext(SensoresContext);
  const [filteredSensores, setFilteredSensores] = useState([]);
  const [filteredSensoresCliente, setFilteredSensoresCliente] = useState([]);
  const { clientes, setClientes } = useContext(ClientsContext);
  const [activeSensor, setActiveSensor] = useState(0);
  const [ultimoVolume, setUltimoVolume] = useState(null);
  const [sensor, setSensor] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    const getAllSensores = async () => {
      setLoading(true)
      try {
        const { data } = await api.get("/modulos", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const sensoresRevertidos = data.reverse();
        setSensores(sensoresRevertidos);
        const valorRetornadoPelaApi = Number(data.ultimaLeitura.volume);
        const valorFormatado = valorRetornadoPelaApi.toFixed(2);
        setUltimoVolume(valorFormatado);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          // Se o erro for 403 (Forbidden)      
          navigate('/login')
        } else {
          // Se for um erro diferente de 403, faça o log do erro
          console.log(error);
        } } finally {
        setLoading(false);
      }
    };
  
    getAllSensores();
  }, []);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    const getAllClientes = async () => {
      try {
        const { data } = await api.get("/clientes", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setClientes(data);
      } catch (error) {
        console.log(error);
      }
    };

    getAllClientes();
  }, []);


  const submitSearch = (data) => {
    reset({ searchSensor: "" });
    setSearchSensor(searchSensor);
  };

  const submitSearchCliente = (data) => {
    reset({ searchSensor: "" });
    setSearchSensorCliente(searchSensor);
  };

  useEffect(() => {
    const filteredSensoresList = sensores.filter(
      (sensor) =>
        sensor.nome.toLowerCase().includes(searchSensor.toLowerCase()) ||
        sensor.id == searchSensor
    );

    setFilteredSensores(filteredSensoresList);
  }, [searchSensor, sensores]);

  useEffect(() => {
    const filteredSensoresListCliente = clientes.filter((cliente) => {
      return (
        cliente.nome
          .toLowerCase()
          .includes(searchSensorCliente.toLowerCase()) ||
        cliente.cpf?.includes(searchSensorCliente) ||
        cliente.cnpj?.includes(searchSensorCliente)
      );
    });

    setFilteredSensoresCliente(filteredSensoresListCliente);
  }, [searchSensorCliente, clientes]);

  const sensoresList = searchSensor ? filteredSensores : sensores;

  const sensoresClienteList = searchSensorCliente
    ? filteredSensoresCliente
    : clientes;

 

  if (loading) {
    return <Loading/>;
  }

  return (
    <>
      <Header />
      <div className=" dashContainerBoxAdmin">
        <div className="containerPainel">
          <div className="titlePag">
            <RxDashboard size={45} /> <h2 className="titleSensores">Painel</h2>
          </div>
        

          <div className="dash">
            <section className="searchBox">
              <h2>Últimas leituras</h2>
              <ul className="listaLeitura overflow">
                {sensores.map((sensor) => (
                  <li className="ultimaLeitura" key={sensor.id}>
                    <div className="leitura">
                      <p>
                        Id do Módulo: <strong>{sensor.id}</strong>{" "}
                      </p>
                      <p>
                        Cliente:{" "}
                        <strong>
                          {sensor.cliente
                            ? sensor.cliente.nome
                            : "Sem cliente atribuído"}
                        </strong>
                      </p>
                    </div>
                    <div className="leitura">
                      {sensor.ultimaLeitura ? (
                        <>
                          <p>
                            Volume:{" "}
                            <strong>
                              {Number(sensor.ultimaLeitura.porcentagemVolume.toFixed(0))} %
                            </strong>
                          </p>
                          <p>
                            Data: <strong>{sensor.ultimaLeitura.data}</strong>
                          </p>
                          {/* {sensor && sensor.tanque !== null ? (
                            // Se sensor.tanque não for nulo
                            percentualVolume < 30 || percentualVolume >= 0 ? (
                              <p>
                                Percentual Volume:{" "}
                                <strong style={{ color: "orange" }}>
                                  {percentualVolume} %
                                </strong>
                              </p>
                            ) : (
                              <p>
                                Percentual Volume:{" "}
                                <strong style={{ color: "blue" }}>
                                  {percentualVolume} %
                                </strong>
                              </p>
                            )
                          ) : null} */}
                        </>
                      ) 
                      : (
                        <p>
                          <strong>Sem leituras cadastradas</strong>
                        </p>
                      )}
                      <Link className="botao" to={`/modulos/${sensor.id}`}>
                        Visualizar módulo
                      </Link>
                    </div>
                  </li>
                ))}
              </ul>
            </section>

            <section>
            <div className="totalPainel">
            <div className="painelCard">
              <h2 style={{ fontSize: "1rem" }} className="title">
                Total de <br></br> módulos
              </h2>
              <h2 style={{ fontSize: "2rem" }} className="number">
                {sensores.length}
              </h2>
            </div>

            <div className="painelCard">
              <h2 style={{ fontSize: "1rem" }} className="title">
                Total de clientes
              </h2>
              <h2 style={{ fontSize: "2rem" }} className="number">
                {clientes.length}
              </h2>
            </div>
          </div>
              <section className="searchBox">
                <div className="containerSearch">
                  <p>Buscar cliente</p>
                  <form
                    onSubmit={handleSubmit(submitSearchCliente)}
                    className="formSearch"
                  >
                    <input
                      type="text"
                      {...register("searchSensorCliente")}
                      value={searchSensorCliente}
                      placeholder="Digite nome do cliente ou CNPJ/CPF"
                      onChange={(e) => setSearchSensorCliente(e.target.value)}
                    />
                    <button type="submit" className="btnSearch">
                      <MdSearch size={21} />
                    </button>
                  </form>
                </div>
                <div className=" container">
                  {sensoresClienteList?.length === 0 ? (
                    <p
                      style={{
                        position: "relative",
                        justifyContent: "center",
                        color: "white",
                        margin: "30px",
                        padding: "34px",
                      }}
                    >
                      Nenhum resultado encontrado.
                    </p>
                  ) : (
                    <ul className="listas2 overflow">
                      {sensoresClienteList.map((cliente, index) => (
                        <SensoresInfoCardPainelCliente
                          key={index}
                          cliente={cliente}
                        />
                      ))}
                    </ul>
                  )}
                </div>
              </section>
              <section className="searchBox">
                <div className="containerSearch">
                  <p>Buscar módulo por nome ou Id</p>
                  <form
                    onSubmit={handleSubmit(submitSearch)}
                    className="formSearch"
                  >
                    <input
                      type="text"
                      {...register("searchSensor")}
                      value={searchSensor}
                      placeholder="Digite Id ou nome do módulo"
                      onChange={(e) => setSearchSensor(e.target.value)}
                    />
                    <button type="submit" className="btnSearch">
                      <MdSearch size={21} />
                    </button>
                  </form>
                </div>
                <div className="container">
                  {sensoresList?.length === 0 ? (
                    <p
                      style={{
                        position: "relative",
                        justifyContent: "center",
                        color: "white",
                        margin: "30px",
                        padding: "34px",
                      }}
                    >
                      Nenhum resultado encontrado.
                    </p>
                  ) : (
                    <ul className="listas2 overflow">
                      {sensoresList.map((sensor, index) => (
                        <SensoresInfoCardPainel key={index} sensor={sensor} />
                      ))}
                    </ul>
                  )}
                </div>
              </section>
            </section>
          </div>
        </div>
      </div>

      {/* <Footer /> */}
    </>
  );
};
