import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./components/AuthContext";
import { ClientsProvider } from "./providers/ClientesContext";
import { TanqueProvider } from "./providers/TanquesContext";
import { SensoresProvider } from "./providers/SensoresContext";

const rootElement = document.getElementById("root");

const root = createRoot(rootElement);

root.render(
  <AuthProvider>
    <SensoresProvider>
      <ClientsProvider>
        <TanqueProvider>
          <App />
        </TanqueProvider>
      </ClientsProvider>
    </SensoresProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
