// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loadingBox {
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;

    height: 100vh;
    width: 100%;

}

.paragraph{
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Loading/Loading.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,sBAAsB;;IAEtB,aAAa;IACb,WAAW;;AAEf;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".loadingBox {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n\r\n    flex-direction: column;\r\n\r\n    height: 100vh;\r\n    width: 100%;\r\n\r\n}\r\n\r\n.paragraph{\r\n    color: white;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
