import { z } from "zod";

export const CadaSensorFormSchema = z.object({

    nome: z.string().nonempty("O nome é obrigatório"),

  clienteId: z.string(),

  tanqueId: z.string(),
});
