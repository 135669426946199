import React, { createContext, useState } from "react";

export const ClientsContext = createContext();

export const ClientsProvider = ({ children }) => {
  const [selectedClient, setSelectedClient] = useState("");
  const [clientes, setClientes] = useState([]);
  return (
    <ClientsContext.Provider
      value={{
        selectedClient,
        setSelectedClient,
        clientes,
        setClientes,
      }}
    >
      {children}
    </ClientsContext.Provider>
  );
};
