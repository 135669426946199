import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { HeaderUser } from "./HeaderUser";
import { api } from "../../../services/api";
import { FuncionariosFormSchema } from "../../PaginaIndividual/ValidacaoZod/FuncionarioFormSchema";
import { VeiculoFormSchema } from "../../PaginaIndividual/ValidacaoZod/VeiculoFormSchema";

export const Funcionarios = () => {
  const { idParam } = useParams();
  const [unicoCliente, setUnicoCliente] = useState(null);
  const [unicoClienteId, setUnicoClienteId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [frentistas, setFrentistas] = useState([]);
  const [motoristas, setMotoristas] = useState([]);
  const [veiculos, setVeiculos] = useState([]);
  const [motoristaComModais, setMotoristaComModais] = useState({});
  const [veiculoComModais, setVeiculoComModais] = useState({});
  const [veiculoEditComModais, setVeiculoEditComModais] = useState({});
  const [frentistaComModais, setFrentistaComModais] = useState({});
  const [frentistaDeleteId, setFrentistaDeleteId] = useState("");
  const [motoristaDeleteId, setMotoristaDeleteId] = useState("");
  const [veiculoDeleteId, setVeiculoDeleteId] = useState("");
  const [veiculoEditId, setVeiculoEditId] = useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isAddingFuncionario, setIsAddingFuncionario] = useState(false);
  const navigate = useNavigate();

  const {
    register: registerFrentista,
    handleSubmit: handleSubmitFrentista,
    reset: resetFrentista,
    formState: { errors: errorsFrentista },
  } = useForm({
    resolver: zodResolver(FuncionariosFormSchema),
  });
  const {
    register: registerMotorista,
    handleSubmit: handleSubmitMotorista,
    reset: resetMotorista,
    formState: { errors: errorsMotorista },
  } = useForm({
    resolver: zodResolver(FuncionariosFormSchema),
  });
  const {
    register: registerVeiculo,
    handleSubmit: handleSubmitVeiculo,
    reset: resetVeiculo,
    formState: { errors: errorsVeiculo },
  } = useForm({
    resolver: zodResolver(VeiculoFormSchema),
  });

  const {
    register: registerEditVeiculo,
    handleSubmit: handleSubmitEditVeiculo,
    reset: resetEditVeiculo,
    formState: { errors: errorsEditVeiculo },
  } = useForm();

  useEffect(() => {
    const loadFrentistas = async () => {
      const token = JSON.parse(localStorage.getItem("token"));

      try {
        const { data } = await api.get(`/clientes/${idParam}/lista-frentista`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setFrentistas(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    const loadMotoristas = async () => {
      const token = JSON.parse(localStorage.getItem("token"));

      try {
        const { data } = await api.get(`/clientes/${idParam}/lista-motorista`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setMotoristas(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    const loadVeiculos = async () => {
      const token = JSON.parse(localStorage.getItem("token"));

      try {
        const { data } = await api.get(`/clientes/${idParam}/lista-veiculo`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setVeiculos(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    loadVeiculos();
    loadFrentistas();
    loadMotoristas();
  }, [idParam]);

  const confirmRemoveFuncionario = (frentistaDeleteId) => {
    setFrentistaComModais({
      ...frentistaComModais,
      [frentistaDeleteId]: true,
    });

    setFrentistaDeleteId(frentistaDeleteId);
    setShowDeleteConfirmation(frentistaDeleteId);
  };

  const deleteFrentista = async () => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      await api.delete(`/clientes/deleteFrentista/${frentistaDeleteId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const newFuncionariosList = frentistas.filter(
        (e) => e.id !== frentistaDeleteId
      );
      setFrentistas(newFuncionariosList);
      toast.success("Frentista excluído com sucesso");
    } catch (error) {
      console.log(error);
      toast.error("Ops alguma coisa deu errado!");
    }
  };

  const confirmRemoveMotorista = (motoristaDeleteId) => {
    setMotoristaComModais({
      ...motoristaComModais,
      [motoristaDeleteId]: true,
    });

    setMotoristaDeleteId(motoristaDeleteId);
    setShowDeleteConfirmation(motoristaDeleteId);
  };

  const confirmRemoveVeiculo = (veiculoDeleteId) => {
    setVeiculoComModais({
      ...veiculoComModais,
      [veiculoDeleteId]: true,
    });
    setVeiculoDeleteId(veiculoDeleteId);
    setShowDeleteConfirmation(veiculoDeleteId);
  };

  const confirmEditVeiculo = (veiculoEditId) => {
    setVeiculoEditComModais({
      ...veiculoEditComModais,
      [veiculoEditId]: true,
    });
    setVeiculoEditId(veiculoEditId);
  };

  const deleteMotorista = async () => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      await api.delete(`/clientes/deleteMotorista/${motoristaDeleteId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const newFuncionariosList = motoristas.filter(
        (e) => e.id !== motoristaDeleteId
      );
      setMotoristas(newFuncionariosList);
      toast.success("Motorista excluído com sucesso");
    } catch (error) {
      console.log(error);
      toast.error("Ops alguma coisa deu errado!");
    }
  };

  const deleteVeiculo = async () => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      await api.delete(`/clientes/deleteVeiculo/${veiculoDeleteId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const newVeiculosList = veiculos.filter(
        (e) => e.placa !== veiculoDeleteId
      );
      setVeiculos(newVeiculosList);
      toast.success("Veículo excluído com sucesso");
    } catch (error) {
      console.log(error);
      toast.error("Ops alguma coisa deu errado!");
    }
  };

  const editVeiculo = async (formData) => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const { data } = await api.put(
        `/clientes/alterarVeiculo/${veiculoEditId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const updatedVeiculos = veiculos.map((veiculo) =>
        veiculo.placa === veiculoEditId ? { ...veiculo, ...data } : veiculo
      );
      setVeiculos(updatedVeiculos);
      toast.success("Veículo editado com sucesso");
      setVeiculoEditComModais(false);
    } catch (error) {
      console.log(error);
      toast.error("Ops alguma coisa deu errado!");
    }
  };

  const addFrentista = async (formData) => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const { data } = await api.post(
        `/clientes/${idParam}/adicionarFrentista`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setFrentistas([...frentistas, data]);
      toast.success("Frentista adicionado com sucesso!");
    } catch (error) {
      console.log(error);
      toast.error("Ops alguma coisa deu errado!");
    }
  };

  const addVeiculo = async (formData) => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const { data } = await api.post(
        `/clientes/${idParam}/adicionarVeiculo`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setVeiculos([...veiculos, data]);
      toast.success("Veículo adicionado com sucesso!");
    } catch (error) {
      console.log(error);
      toast.error("Ops alguma coisa deu errado!");
    }
  };

  const addMotorista = async (formData) => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const { data } = await api.post(
        `/clientes/${idParam}/adicionarMotorista`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setMotoristas([...motoristas, data]);
      toast.success("Motorista adicionado com sucesso!");
    } catch (error) {
      console.log(error);
      toast.error("Ops alguma coisa deu errado!");
    }
  };

  const submitFrentista = async (formData) => {
    await addFrentista(formData);
    resetFrentista({ id: "", nome: "" });
  };

  const submitMotorista = async (formData) => {
    await addMotorista(formData);
    resetMotorista({ id: "", nome: "" });
  };

  const submitVeiculo = async (formData) => {
    await addVeiculo(formData);
    resetVeiculo({ placa: "", modelo: "", odometro: "" });
  };

  const submitEditVeiculo = async (formData) => {
    await editVeiculo(formData);
    resetEditVeiculo();
  };

  const closeEditVeiculo = () => {
    setVeiculoEditComModais(false);
    resetEditVeiculo();
  };
  return (
    <>
      <HeaderUser />
      <div className="visualizarBox">
        <div className="container containerVisualizar containerFuncionariosArea ">
          <>
            {loading ? (
              <p style={{alignSelf: "center"}}>Carregando...</p>
            ) : (
              <>
                <div>
                  <div className="containerFuncionario">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        gap: "35px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          marginTop: "3rem",
                        }}
                      >
                        <div
                          style={{
                            width: "45%",
                          }}
                          className="funcionario background"
                        >
                          <h2 style={{ fontSize: "1.1rem" }}>
                            Adicionar Frentista
                          </h2>
                          <form
                            onSubmit={handleSubmitFrentista(submitFrentista)}
                          >
                            <div className="input-container">
                              <input
                                type="text"
                                placeholder="ID do Frentista"
                                {...registerFrentista("id")}
                              />
                              {errorsFrentista.id ? (
                                <p>{errorsFrentista.id.message}</p>
                              ) : null}
                              <input
                                type="text"
                                placeholder="Nome do Frentista"
                                {...registerFrentista("nome")}
                              />
                              {errorsFrentista.nome ? (
                                <p>{errorsFrentista.nome.message}</p>
                              ) : null}

                              <button
                                type="submit"
                                className="custom-button button"
                                onClick={() => {
                                  setFrentistaComModais(false);
                                }}
                              >
                                Cadastrar Frentista
                              </button>
                            </div>
                          </form>
                        </div>

                        <div
                          style={{
                            width: "45%",
                          }}
                          className="funcionario background"
                        >
                          <h2 style={{ fontSize: "1.1rem" }}>
                            Adicionar Motorista
                          </h2>
                          <form
                            onSubmit={handleSubmitMotorista(submitMotorista)}
                          >
                            <div className="input-container">
                              <input
                                type="text"
                                placeholder="ID do Motorista"
                                {...registerMotorista("id")}
                              />
                              {errorsMotorista.id ? (
                                <p>{errorsMotorista.id.message}</p>
                              ) : null}
                              <input
                                type="text"
                                placeholder="Nome do Motorista"
                                {...registerMotorista("nome")}
                              />
                              {errorsMotorista.nome ? (
                                <p>{errorsMotorista.nome.message}</p>
                              ) : null}

                              <button
                                type="submit"
                                className="custom-button button"
                                onClick={() => {
                                  setMotoristaComModais(false);
                                }}
                              >
                                Cadastrar Motorista
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>

                      <div className="funcionario background">
                        <h2 style={{ fontSize: "1.1rem" }}>
                          Adicionar Veículo
                        </h2>
                        <form onSubmit={handleSubmitVeiculo(submitVeiculo)}>
                          <div className="input-container">
                            <input
                              type="text"
                              placeholder="Placa do veículo"
                              {...registerVeiculo("placa")}
                            />
                            {errorsVeiculo.placa ? (
                              <p>{errorsVeiculo.placa.message}</p>
                            ) : null}
                            <input
                              type="text"
                              placeholder="Modelo do veículo"
                              {...registerVeiculo("modelo")}
                            />
                            {errorsVeiculo.modelo ? (
                              <p>{errorsVeiculo.modelo.message}</p>
                            ) : null}
                            <input
                              type="text"
                              placeholder="Odômetro do veículo"
                              {...registerVeiculo("odometro")}
                            />
                            {errorsVeiculo.odometro ? (
                              <p>{errorsVeiculo.odometro.message}</p>
                            ) : null}

                            <button
                              type="submit"
                              className="custom-button button"
                              onClick={() => {
                                setVeiculoComModais(false);
                              }}
                            >
                              Cadastrar Veículo
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      minHeight: "500px",
                      width: "100%",
                    }}
                  >
                    <ul
                      className="background"
                      style={{
                        paddingTop: "35px",
                        maxHeight: "250px",
                        overflow: "auto",
                      }}
                    >
                      {frentistas && frentistas.length > 0 ? (
                        <>
                          <div className="titlePag">
                            <h2 style={{ fontSize: "1.1rem" }}>
                              Frentistas cadastrados
                            </h2>
                          </div>
                          {frentistas.map((e) => (
                            <li
                              style={{
                                borderBottom: "1px solid black",
                                width: "100%",
                              }}
                              key={e.id}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  gap: "20px",
                                  width: "100%",
                                }}
                              >
                                <p>
                                  Nome: <strong>{e.nome}</strong>
                                </p>
                                <p>
                                  ID: <strong>{e.id}</strong>
                                </p>

                                <button
                                  className="botao-remove2"
                                  onClick={() => confirmRemoveFuncionario(e.id)}
                                >
                                  Remover
                                </button>
                              </div>
                              {frentistaComModais[e.id] ? (
                                <div className="confirmation-modal">
                                  <div className="confirmation-box">
                                    <p>
                                      Tem certeza de que deseja apagar o
                                      frentista {e.nome}?
                                    </p>
                                    <div className="confirmation-buttons">
                                      <button
                                        className="confirm-button"
                                        onClick={() => deleteFrentista(e.id)}
                                      >
                                        Confirmar
                                      </button>
                                      <button
                                        className="cancel-button"
                                        onClick={() => {
                                          setFrentistaComModais(false);
                                        }}
                                      >
                                        Cancelar
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </li>
                          ))}
                        </>
                      ) : (
                        <p>Sem frentistas cadastrados</p>
                      )}
                    </ul>

                    <ul
                      className="background"
                      style={{
                        paddingTop: "35px",
                        maxHeight: "250px",
                        overflow: "auto",
                      }}
                    >
                      {motoristas && motoristas.length > 0 ? (
                        <>
                          <div className="titlePag">
                            <h2 style={{ fontSize: "1.1rem" }}>
                              Motoristas cadastrados
                            </h2>
                          </div>
                          {motoristas.map((e) => (
                            <li
                              style={{
                                borderBottom: "1px solid black",
                                width: "100%",
                              }}
                              key={e.id}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  gap: "20px",
                                  width: "100%",
                                }}
                              >
                                <p>
                                  Nome: <strong>{e.nome}</strong>
                                </p>
                                <p>
                                  ID: <strong>{e.id}</strong>
                                </p>

                                <button
                                  className="botao-remove2"
                                  onClick={() => confirmRemoveMotorista(e.id)}
                                >
                                  Remover
                                </button>
                              </div>
                              {motoristaComModais[e.id] ? (
                                <div className="confirmation-modal">
                                  <div className="confirmation-box">
                                    <p>
                                      Tem certeza de que deseja apagar o
                                      motorista {e.nome}?
                                    </p>
                                    <div className="confirmation-buttons">
                                      <button
                                        className="confirm-button"
                                        onClick={() => deleteMotorista(e.id)}
                                      >
                                        Confirmar
                                      </button>
                                      <button
                                        className="cancel-button"
                                        onClick={() => {
                                          setMotoristaComModais(false);
                                        }}
                                      >
                                        Cancelar
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </li>
                          ))}
                        </>
                      ) : (
                        <p>Sem motoristas cadastrados</p>
                      )}
                    </ul>

                    <ul
                      className="background"
                      style={{
                        paddingTop: "35px",
                        maxHeight: "250px",
                        overflow: "auto",
                      }}
                    >
                      {veiculos && veiculos.length > 0 ? (
                        <>
                          <div className="titlePag">
                            <h2 style={{ fontSize: "1.1rem" }}>
                              Veículos cadastrados
                            </h2>
                          </div>
                          {veiculos.map((e) => (
                            <li
                              style={{
                                borderBottom: "1px solid black",
                                width: "100%",
                              }}
                              key={e.placa}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  gap: "20px",
                                  width: "100%",
                                }}
                              >
                                <p>
                                  Placa: <strong>{e.placa}</strong>
                                </p>
                                <p>
                                  Modelo: <strong>{e.modelo}</strong>
                                </p>
                                <p>
                                  Odômetro:{" "}
                                  <strong>{Number(e.odometro)} km</strong>
                                </p>

                                <button
                                  className="botao-edit"
                                  onClick={() => confirmEditVeiculo(e.placa)}
                                >
                                  Editar
                                </button>
                                <button
                                  className="botao-remove2"
                                  onClick={() => confirmRemoveVeiculo(e.placa)}
                                >
                                  Remover
                                </button>
                              </div>
                              {veiculoComModais[e.placa] ? (
                                <div className="confirmation-modal">
                                  <div className="confirmation-box">
                                    <p>
                                      Tem certeza de que deseja apagar o veículo
                                      de placa {e.placa}?
                                    </p>
                                    <div className="confirmation-buttons">
                                      <button
                                        className="confirm-button"
                                        onClick={() => deleteVeiculo(e.placa)}
                                      >
                                        Confirmar
                                      </button>
                                      <button
                                        className="cancel-button"
                                        onClick={() => {
                                          setVeiculoComModais(false);
                                        }}
                                      >
                                        Cancelar
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {veiculoEditComModais[e.placa] ? (
                                <div className="confirmation-modal">
                                  <div className="confirmation-box">
                                    <h3>Editar veículo de placa {e.placa}</h3>
                                    <form
                                      onSubmit={handleSubmitEditVeiculo(
                                        submitEditVeiculo
                                      )}
                                    >
                                      <input
                                        type="hidden"
                                        className="inputEditar"
                                        {...registerEditVeiculo("placa")}
                                        defaultValue={e.placa}
                                      />
                                      <input
                                        type="hidden"
                                        className="inputEditar"
                                        {...registerEditVeiculo("clienteId")}
                                        defaultValue={idParam}
                                      />

                                      <label>
                                        Modelo do Veículo
                                        <input
                                          type="text"
                                          className="inputEditar"
                                          {...registerEditVeiculo("modelo")}
                                          defaultValue={e.modelo}
                                        />
                                      </label>

                                      <label>
                                        Odômetro
                                        <input
                                          type="text"
                                          className="inputEditar"
                                          {...registerEditVeiculo("odometro")}
                                          defaultValue={e.odometro}
                                        />
                                      </label>
                                      <div className="confirmation-buttons">
                                        <button
                                          className="confirm-button"
                                          type="submit"
                                        >
                                          Confirmar
                                        </button>
                                        <button
                                          className="cancel-button"
                                          onClick={() => {
                                            closeEditVeiculo();
                                          }}
                                        >
                                          Cancelar
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              ) : null}
                            </li>
                          ))}
                        </>
                      ) : (
                        <p>Sem veículos cadastrados</p>
                      )}
                    </ul>
                  </div>
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </>
  );
};
