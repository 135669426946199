import { useContext, useEffect } from "react";
import { TanqueContext } from "../../providers/TanquesContext";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { api } from "../../services/api";
import { toast } from "react-toastify";
import { ClientsContext } from "../../providers/ClientesContext";
import "./Tanques.css";
import { TanquesDeitadoFormSchema } from "./ValidacoesZod/TanquesDeitadoFormSchema";
import { useNavigate } from "react-router";
import { SensoresContext } from "../../providers/SensoresContext";

export const AdicionarCilindricoDeitado = () => {
  const { tanques, setTanques } = useContext(TanqueContext);
  const { sensores, setSensores } = useContext(SensoresContext);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(TanquesDeitadoFormSchema),
  });

  const navigate = useNavigate();

  useEffect(() => {
    const getAllSensores = async () => {
      const token = JSON.parse(localStorage.getItem("token"));
      try {
        const { data } = await api.get("/modulos", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const sensoresRevertidos = data.reverse();
        setSensores(sensoresRevertidos);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          // Se o erro for 403 (Forbidden)      
          navigate('/')
        } else {
          // Se for um erro diferente de 403, faça o log do erro
          console.log(error);} 
      }
    };

    getAllSensores();
  }, []);

  const addTanqueDeitado = async (formData) => {
    const token = JSON.parse(localStorage.getItem("token"));

    try {
      const { data } = await api.post(
        "/tanques/salvarTanqueCilindricoDeitado",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setTanques([...tanques, data]);
      navigate("/tanques");
      toast.success("Tanque adicionado com sucesso");
    } catch (error) {
      console.log(error);
      toast.success("Ops! Alguma coisa deu errado.");
    }
  };

  const submit = (formData) => {
    addTanqueDeitado(formData);
    reset({ nome: "", comprimento: "", raio: "", correcao: "", moduloId: "" });
  };

  return (
    <>
      <Header />
      <div className="flexBox">
        <div className="containerBox">
          <form onSubmit={handleSubmit(submit)}>
            <p>Adicionar tanque cilindrico horizontal</p>
            <input
              type="text"
              placeholder="Nome do Tanque"
              {...register("nome")}
            />
            {errors.nome ? <p>{errors.nome.message}</p> : null}
            <input
              type="text"
              placeholder="Comprimento em cm"
              {...register("comprimento")}
            />
            {errors.comprimento ? <p>{errors.comprimento.message}</p> : null}
            <input
              type="text"
              placeholder="Raio em cm"
              {...register("raio")}
            />
            {errors.raio ? <p>{errors.raio.message}</p> : null}
            <input
              type="text"
              placeholder="Correção em mm"
              {...register("correcao")}
            />
          
            {errors.correcao ? <p>{errors.correcao.message}</p> : null}

            {/* <label>
              {" "}
              Módulo
              <select
                className="select-input"
                {...register("moduloId")}
                defaultValue={""}
              >
                <option value={""}>Selecionar módulo</option>
                {sensores.map((sensor) => (
                  <option key={sensor.id} value={sensor.id}>
                    {sensor.nome} id:{sensor.id}
                  </option>
                ))}
              </select>
            </label> */}

            <button type="submit" className="custom-button button">
              Cadastrar Tanque
            </button>
            <button
              className="cancel-button-cliente"
              onClick={() => navigate("/tanques")}
            >
              Cancelar
            </button>
          </form>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};
