/* eslint-disable no-loop-func */
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { api } from "../../../services/api";
import { SlPeople } from "react-icons/sl";
import { GiWaterTank } from "react-icons/gi";
import horizontal0 from "../../../assets/horizontal0.png";
import horizontal10 from "../../../assets/horizontal10.png";
import horizontal20 from "../../../assets/horizontal20.png";
import horizontal30 from "../../../assets/horizontal30.png";
import horizontal40 from "../../../assets/horizontal40.png";
import horizontal50 from "../../../assets/horizontal50.png";
import horizontal60 from "../../../assets/horizontal60.png";
import horizontal70 from "../../../assets/horizontal70.png";
import horizontal80 from "../../../assets/horizontal80.png";
import horizontal90 from "../../../assets/horizontal90.png";
import horizontal100 from "../../../assets/horizontal100.png";
import vertical0 from "../../../assets/vertical0.png";
import vertical10 from "../../../assets/vertical0.png";
import vertical20 from "../../../assets/vertical20.png";
import vertical30 from "../../../assets/vertical30.png";
import vertical40 from "../../../assets/vertical40.png";
import vertical50 from "../../../assets/vertical50.png";
import vertical60 from "../../../assets/vertical60.png";
import vertical70 from "../../../assets/vertical70.png";
import vertical80 from "../../../assets/vertical80.png";
import vertical90 from "../../../assets/vertical90.png";
import vertical100 from "../../../assets/vertical100.png";
import retangular0 from "../../../assets/retangular0.png";
import retangular10 from "../../../assets/retangular10.png";
import retangular20 from "../../../assets/retangular20.png";
import retangular30 from "../../../assets/retangular30.png";
import retangular40 from "../../../assets/retangular40.png";
import retangular50 from "../../../assets/retangular50.png";
import retangular60 from "../../../assets/retangular60.png";
import retangular70 from "../../../assets/retangular70.png";
import retangular80 from "../../../assets/retangular80.png";
import retangular90 from "../../../assets/retangular90.png";
import retangular100 from "../../../assets/retangular100.png";

import XLSX from "xlsx";
import { saveAs } from "file-saver";
import { HeaderUser } from "./HeaderUser";
import { Loading } from "../../Loading/Loading";

export const LeiturasUser = () => {
  const [unicoSensor, setUnicoSensor] = useState({});
  const [loading, setLoading] = useState(true);
  const { idParam } = useParams();
  const [ultimaLeitura, setUltimaLeitura] = useState(null);
  const [ultimaLeituraData, setUltimaLeituraData] = useState(null);
  const [leituras, setLeituras] = useState([]);
  const [sensorID, setSensorID] = useState(null);
  const [idTanque, setIdTanque] = useState(null);
  const [todosVolumes, setTodosVolumes] = useState([]);
  const [comprimentoTanque, setComprimentoTanque] = useState(0);
  const [ultimoVolume, setUltimoVolume] = useState(null);
  const [ultimoVolumeApi, setUltimoVolumeApi] = useState(null);
  const [ultimaTemperatura, setUltimaTemperatura] = useState(null);
  const [abastecimento, setAbastecimento] = useState(false);
  const [qntAbastecido, setQntAbastecido] = useState(null);
  const [totalizador, setTotalizador] = useState(null);
  const [tanque, setTanque] = useState({});
  const [graphsIsLoaded, setGraphIsLoaded] = useState(false);
  const [dados, setDados] = useState({});
  const [alturaCorrigida, setAlturaCorrigida] = useState(null);
  const [tds, setTds] = useState(null);
  const [porcentagemVolume, setPorcentagemVolume] = useState(null);
  const [carregouDados, setCarregouDados] = useState(false);
  const [capacidadeTotal, setCapacidadeTotal] = useState(null);
  const [LeiturasAbastecimento, setLeiturasAbastecimento] = useState(null);
  const [isRelatorioPronto, setIsRelatorioPronto] = useState(false);
  const [odometro, setOdometro] = useState(null);
  const [motorista, setMotorista] = useState(null);
  const [frentista, setFrentista] = useState(null);
  const [placa, setPlaca] = useState(null);
  const [modelo, setModelo] = useState(null);

  const [todasLeituras, setTodasLeituras] = useState([]);
  const navigate = useNavigate();
  // useEffect(() => {
  const loadSensor = async () => {
    const token = JSON.parse(localStorage.getItem("token"));

    try {
      const { data } = await api.get(`/modulos/${idParam}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUnicoSensor(data);
      setSensorID(data.id);
      setIdTanque(data.tanque.id);
      setTanque(data.tanque);
      setOdometro(data.ultimaLeitura.odometro);
      setPlaca(data.ultimaLeitura.placa?.placa);
      setModelo(data.ultimaLeitura.placa?.modelo);
      setMotorista(data.ultimaLeitura.motorista?.nome);
      setFrentista(data.ultimaLeitura.frentista?.nome);
      setUltimaLeitura(data.ultimaLeitura.altura);
      setUltimaLeituraData(data.ultimaLeitura.data);
      setUltimoVolumeApi(data.ultimaLeitura.volume);
      setUltimaTemperatura(data.ultimaLeitura.temp);
      setAbastecimento(data.ultimaLeitura.temAbastecimento);
      setTotalizador(data.ultimaLeitura.totalizador);
      setQntAbastecido(data.ultimaLeitura.qntdAbastecimento);
      setAlturaCorrigida(data.ultimaLeitura.alturaCorrigida);
      setPorcentagemVolume(data.ultimaLeitura.porcentagemVolume);
      setTds(data.ultimaLeitura.tds);
      setGraphIsLoaded(true);

      const role = JSON.parse(localStorage.getItem("admin"));

      if (role) {
        navigate("/login");
      } else if (role === false) {
        navigate(`/user/dashboard/${idParam}`);
      } else {
        navigate("/login");
      }

      const leituras = data.leituras;
      const ids = leituras.map((leitura) => leitura.id);
      const porcentagens = leituras.map((leitura) => leitura.porcentagemVolume);
      const datas = leituras.map((leitura) => leitura.data);

      if (!carregouDados) {
        setDados({
          labels: datas,
          datasets: [
            {
              label: "Volumes",
              data: porcentagens,
              borderColor: "black",
              borderWidth: 2,
              backgroundColor: "blue",
              pointRadius: 0,
            },
          ],
        });
      }
      setCarregouDados(true);

      const valorRetornadoPelaApi = Number(data.ultimaLeitura.volume);
      const valorFormatado = valorRetornadoPelaApi.toFixed(2);
      setUltimoVolume(valorFormatado);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        // Se o erro for 403 (Forbidden)
        navigate("/login");
      } else {
        // Se for um erro diferente de 403, faça o log do erro
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const token = JSON.parse(localStorage.getItem("token"));
  const getAllLeituras = async () => {
    try {
      const { data } = await api.get(`/modulos/${idParam}/lista-leituras`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const leiturasRevertidas = data;

      const datasEmOrdem = leiturasRevertidas.sort((a, b) =>
        a.id < b.id ? 1 : -1
      );

      const ultimasTresLeituras = datasEmOrdem.slice(0, 5);
      const ultimasTresLeiturasOrdem = datasEmOrdem.slice(0, 5);
      setLeiturasAbastecimento(ultimasTresLeiturasOrdem);
      setLeituras(ultimasTresLeituras);
      setTodasLeituras(datasEmOrdem);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Define um intervalo para buscar os dados do sensor a cada 5 segundos (ajuste conforme necessário)
    const intervalId = setInterval(getAllLeituras, 20000);
    const intervalLeitura = setInterval(loadSensor, 20000);
    // const intervalData = setInterval(getDatas, 1000);

    // Executa a função de busca imediatamente ao montar o componente
    // getDatas();
    getAllLeituras();
    loadSensor();

    // Limpa o intervalo quando o componente é desmontado
    return () => {
      // clearInterval(intervalData);
      clearInterval(intervalId);
      clearInterval(intervalLeitura);
    };
  }, []);

  const handleClick = () => {
    // Criar um array de dados no formato desejado
    const data = [
      [
        "DATA",
        "VOLUME",
        "PORCENTAGEM DO VOLUME",
        "TEMPERATURA",
        "TDS",
        "ODÔMETRO",
        "MOTORISTA",
        "FRENTISTA",
        "TOTALIZADOR",
        "QUANTIDADE ABASTECIDA",
      ],
      ...todasLeituras.map((leitura) => [
        leitura.data,
        parseFloat(leitura.volume).toFixed(2),
        leitura.porcentagemVolume,
        Number(leitura.temp),
        Number(leitura.tds),
        leitura.odometro != 0 ? Number(leitura.odometro) : "-",
        leitura.motorista ? leitura.motorista.nome : "-",
        leitura.frentista ? leitura.frentista.nome : "-",
        leitura.totalizador != 0 ? Number(leitura.totalizador) : "-",
        leitura.qntdAbastecimento != 0
          ? Number(leitura.qntdAbastecimento)
          : "-",
      ]),
    ];

    // Criar uma planilha
    const ws = XLSX.utils.aoa_to_sheet(data);

    // Criar um livro
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Relatorio");

    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
    const fileName = `Relatório_${sensorID}_${formattedDate}.xls`;

    XLSX.writeFile(wb, fileName);

    // Marcar o relatório como pronto (se necessário)
    setIsRelatorioPronto(true);
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    const getTanqueComprimento = async () => {
      try {
        const { data } = await api.get(`/tanques/${idTanque}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setComprimentoTanque(data.comprimento);
        setCapacidadeTotal(data.capacidadeTotal);
      } catch (error) {
        console.log(error.response);
      }
    };

    getTanqueComprimento();
  }, [idTanque]);

  const todasQueTemAbastecimento = todasLeituras.filter(
    (leitura) => leitura.temAbastecimento
  );
  const temAbastecimento = todasQueTemAbastecimento.slice(0, 5);

  if (loading) {
    return <Loading />;
  }

  const formatarNumero = (numero) => {
    if (numero >= 0 && numero < 100) {
      const numeroString = numero.toString();
      const parteDecimal = numeroString.slice(-2);
      return "0." + parteDecimal;
    }
    const numeroString = numero.toString();
    const parteInteira = numeroString.slice(0, -2);
    const parteDecimal = numeroString.slice(-2);
    return parteInteira + "." + parteDecimal;
  };

  return (
    <>
      <HeaderUser />
      <div className="visualizarBox">
        <div className="container containerVisualizar">
          {unicoSensor ? (
            <div className="infoBox">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <h3 style={{ position: "relative" }}>MÓDULO</h3>
              </div>

              <p>
                Id do módulo: <strong>{unicoSensor.id}</strong>{" "}
              </p>

              <p>
                Nome do módulo: <strong>{unicoSensor.nome}</strong>
              </p>

              {unicoSensor.tanque?.tipoTanque == "tanque_cilindrico_em_pe" ||
              unicoSensor.tanque?.tipoTanque == "tanque_cilindrico_deitado" ||
              unicoSensor.tanque?.tipoTanque == "tanque_retangular" ? (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <GiWaterTank className="tank" size={25} />
                    <p>
                      Tanque: <strong>{unicoSensor.tanque?.nome}</strong>
                    </p>
                  </div>
                  {unicoSensor.tanque?.tipoTanque ==
                    "tanque_cilindrico_em_pe" && (
                    <>
                      <p>
                        Altura:{" "}
                        <strong>{unicoSensor.tanque?.comprimento} cm</strong>{" "}
                      </p>
                      <p>
                        Raio: <strong>{unicoSensor.tanque?.raio} cm</strong>{" "}
                      </p>
                    </>
                  )}
                  {unicoSensor.tanque?.tipoTanque ==
                    "tanque_cilindrico_deitado" && (
                    <>
                      <p>
                        Comprimento:{" "}
                        <strong>{unicoSensor.tanque?.comprimento} cm</strong>{" "}
                      </p>
                      <p>
                        Raio: <strong>{unicoSensor.tanque?.raio} cm</strong>{" "}
                      </p>
                    </>
                  )}
                  {unicoSensor.tanque?.tipoTanque == "tanque_retangular" && (
                    <>
                      <p>
                        Comprimento:{" "}
                        <strong>{unicoSensor.tanque?.comprimento} cm</strong>{" "}
                      </p>
                      <p>
                        Largura:{" "}
                        <strong>{unicoSensor.tanque?.largura} cm</strong>
                      </p>
                      <p>
                        Altura: <strong>{unicoSensor.tanque?.altura} cm</strong>
                      </p>
                    </>
                  )}
                  <p>
                    Correção: <strong>{tanque.correcao} mm</strong>
                  </p>

                  {unicoSensor.tanque?.tipoTanque ==
                    "tanque_cilindrico_em_pe" && (
                    <p>
                      Tipo: <strong>Cilíndrico Vertical</strong>
                    </p>
                  )}
                  {unicoSensor.tanque?.tipoTanque ==
                    "tanque_cilindrico_deitado" && (
                    <p>
                      Tipo: <strong>Cilíndrico Horizontal</strong>
                    </p>
                  )}
                  {unicoSensor.tanque?.tipoTanque == "tanque_retangular" && (
                    <p>
                      Tipo: <strong>Retangular</strong>
                    </p>
                  )}
                  {unicoSensor.tanque?.tipoTanque ==
                    "tanque_cilindrico_em_pe" ||
                  unicoSensor.tanque?.tipoTanque ==
                    "tanque_cilindrico_deitado" ? (
                    <p>
                      Capacidade total:{" "}
                      <strong>{Number(capacidadeTotal).toFixed(2)} L </strong>
                    </p>
                  ) : (
                    <p>
                      Capacidade total:{" "}
                      <strong> {Number(capacidadeTotal).toFixed(2)} L </strong>
                    </p>
                  )}
                </>
              ) : (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <GiWaterTank className="tank" size={25} />
                    <p>Esse módulo não possui tanque</p>
                  </div>
                </>
              )}
              {unicoSensor.cliente !== null ? (
                <>
                  <div
                    style={{
                      display: "flex",

                      gap: "4px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                                               
                      </div>
                      
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <SlPeople size={20} />
                    <p> Esse módulo não possui cliente</p>
                  </div>
                </>
              )}
              <button className="botao" onClick={handleClick}>
                Gerar relatório
              </button>
              <Link
                className="botao"
                to={`/user/dashboard/${unicoSensor.id}/graficos`}
              >
                Visualizar gráficos
              </Link>
            </div>
          ) : null}
          <div className="leituraBox">
            <div>
              {loading ? (
                <>
                  <div className="infoBoxLeitura5">
                    <h3 style={{ textAlign: "center" }}>Carregando...</h3>{" "}
                  </div>{" "}
                </>
              ) : (
                <>
                  {ultimaLeitura ? (
                    <>
                      <div className="infoBoxLeitura2">
                        <h3 style={{ textAlign: "center" }}>Última leitura</h3>
                        <div className="linhaLeitura">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              gap: "0px",
                            }}
                          >
                            <p style={{ fontSize: "0.9rem" }}>
                              Data: <strong>{ultimaLeituraData}</strong>
                            </p>
                            <p style={{ fontSize: "0.9rem" }}>
                              Altura do espaço aberto:{" "}
                              <strong>{Number(ultimaLeitura)} mm</strong>
                            </p>
                            <p style={{ fontSize: "0.9rem" }}>
                              Altura do espaço aberto corrigida:{" "}
                              <strong>
                                {Number(alturaCorrigida).toFixed(0)} mm
                              </strong>
                            </p>
                            {ultimoVolume !== null && (
                              <p style={{ fontSize: "0.9rem" }}>
                                Volume: <strong>{ultimoVolume} L</strong>
                              </p>
                            )}
                            <p style={{ fontSize: "0.9rem" }}>
                              Porcentual volume:{" "}
                              <strong>
                                {Number(porcentagemVolume).toFixed(2)}%
                              </strong>
                            </p>
                            <p style={{ fontSize: "0.9rem" }}>
                              Temperatura:{" "}
                              <strong>{Number(ultimaTemperatura)} °C</strong>
                            </p>
                            <p style={{ fontSize: "0.9rem" }}>
                              TDS: <strong>{Number(tds)} ppm</strong>
                            </p>
                            <p style={{ fontSize: "0.9rem" }}>
                              Abastecido:{" "}
                              <strong>
                                {qntAbastecido != null
                                  ? `${formatarNumero(Number(qntAbastecido))} L`
                                  : "-"}
                              </strong>
                            </p>
                            <p style={{ fontSize: "0.9rem" }}>
                              Totalizador de abastecimento:{" "}
                              <strong>
                                {totalizador != null
                                  ? `${formatarNumero(Number(totalizador))} L`
                                  : "-"}
                              </strong>
                            </p>{" "}
                            <p style={{ fontSize: "0.9rem" }}>
                              Odômetro:{" "}
                              <strong>
                                {odometro != 0 && placa
                                  ? `${Number(odometro)} km `
                                  : "-"}{" "}
                              </strong>
                            </p>{" "}
                            <p style={{ fontSize: "0.9rem" }}>
                              Motorista:{" "}
                              <strong>{motorista ? motorista : "-"}</strong>
                            </p>{" "}
                            <p style={{ fontSize: "0.9rem" }}>
                              Frentista:{" "}
                              <strong>{frentista ? frentista : "-"}</strong>
                            </p>{" "}
                            <p style={{ fontSize: "0.9rem" }}>
                              Placa: <strong>{placa ? placa : "-"}</strong>
                            </p>{" "}
                            <p style={{ fontSize: "0.9rem" }}>
                              Modelo do veículo: <strong>{modelo ? modelo : "-"}</strong>
                            </p>{" "}
                          </div>

                          {unicoSensor.tanque?.tipoTanque ===
                            "tanque_cilindrico_em_pe" && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              {porcentagemVolume > 99 &&
                              porcentagemVolume <= 100 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(0)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={vertical100}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume > 80 &&
                              porcentagemVolume <= 99 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={vertical90}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume > 70 &&
                              porcentagemVolume <= 80 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={vertical80}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume > 60 &&
                              porcentagemVolume <= 70 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={vertical70}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume > 50 &&
                              porcentagemVolume <= 60 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={vertical60}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume > 40 &&
                              porcentagemVolume <= 50 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={vertical50}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume > 30 &&
                              porcentagemVolume <= 40 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={vertical40}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume > 20 &&
                              porcentagemVolume <= 30 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={vertical30}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume > 10 &&
                              porcentagemVolume <= 20 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={vertical20}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume > 0 &&
                              porcentagemVolume <= 10 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={vertical10}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume === 0 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={vertical0}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                            </div>
                          )}

                          {unicoSensor.tanque?.tipoTanque ===
                            "tanque_cilindrico_deitado" && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              {porcentagemVolume > 99 &&
                              porcentagemVolume <= 100 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(0)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={horizontal100}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume > 80 &&
                              porcentagemVolume <= 99 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={horizontal90}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume > 70 &&
                              porcentagemVolume <= 80 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={horizontal80}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume > 60 &&
                              porcentagemVolume <= 70 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={horizontal70}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume > 50 &&
                              porcentagemVolume <= 60 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={horizontal60}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume > 40 &&
                              porcentagemVolume <= 50 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={horizontal50}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume > 30 &&
                              porcentagemVolume <= 40 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={horizontal40}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume > 20 &&
                              porcentagemVolume <= 30 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={horizontal30}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume > 10 &&
                              porcentagemVolume <= 20 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={horizontal20}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume > 0 &&
                              porcentagemVolume <= 10 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={horizontal10}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume === 0 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={horizontal0}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                            </div>
                          )}

                          {unicoSensor.tanque?.tipoTanque ===
                            "tanque_retangular" && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              {porcentagemVolume > 99 &&
                              porcentagemVolume <= 100 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(0)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={retangular100}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume > 80 &&
                              porcentagemVolume <= 99 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={retangular90}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume > 70 &&
                              porcentagemVolume <= 80 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={retangular80}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume > 60 &&
                              porcentagemVolume <= 70 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={retangular70}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume > 50 &&
                              porcentagemVolume <= 60 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={retangular60}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume > 40 &&
                              porcentagemVolume <= 50 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={retangular50}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume > 30 &&
                              porcentagemVolume <= 40 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={retangular40}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume > 20 &&
                              porcentagemVolume <= 30 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={retangular30}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume > 10 &&
                              porcentagemVolume <= 20 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={retangular20}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume > 0 &&
                              porcentagemVolume <= 10 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>
                                  <img
                                    className="imgLeitura"
                                    src={retangular10}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                              {porcentagemVolume === 0 ? (
                                <>
                                  <span className="porcentagem">
                                    {Number(porcentagemVolume).toFixed(2)} %
                                  </span>

                                  <img
                                    className="imgLeitura"
                                    src={retangular0}
                                    alt="Tanque"
                                  />
                                </>
                              ) : null}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        padding: "30px",
                      }}
                    >
                      <p>Ainda não foi cadastradas nenhuma leitura.</p>
                      <p>Cadastrar leituras deste módulo na url:</p>

                      <p>
                        {" "}
                        pelo ID na url (primeiro cadastrar módulo e ID na aba
                        "Módulos"):
                      </p>
                      <p>
                        http://martplore.com.br/api/modulos/salvarLeitura?leitura=(valores*)
                        .
                      </p>
                      <p>
                        *Precisam ser substituídos pelos valores enviado pelo
                        sensor.
                      </p>
                    </div>
                  )}{" "}
                </>
              )}
            </div>
            <div>
              {ultimaLeitura ? (
                <>
                  <div className="containerLeitura">
                    <div className="infoBoxLeitura3">
                      <h4 style={{ textAlign: "center" }}>Últimas Leituras</h4>
                      <ul>
                        {leituras.map((leitura, index) => (
                          <div key={index}>
                            <li style={{ borderBottom: "1px solid black" }}>
                              <div key={index} className="linhaLeituras">
                                <p style={{ fontSize: "0.8rem" }}>
                                  Data: <strong>{leitura.data}</strong>
                                </p>

                                <p style={{ fontSize: "0.8rem" }}>
                                  Altura do espaço aberto:{" "}
                                  <strong>
                                    {Number(leitura.alturaCorrigida)} mm
                                  </strong>
                                </p>

                                <p style={{ fontSize: "0.8rem" }}>
                                  Volume:{" "}
                                  <strong>
                                    {Number(leitura.porcentagemVolume).toFixed(
                                      0
                                    )}{" "}
                                    %
                                  </strong>
                                </p>
                              </div>{" "}
                            </li>{" "}
                          </div>
                        ))}
                      </ul>
                    </div>
                    <div className="infoBoxLeitura4">
                      <h4 style={{ textAlign: "center" }}>
                        Últimos Abastecimentos
                      </h4>

                      <ul>
                        <>
                          {temAbastecimento.map((leitura, index) => (
                            <li
                              key={index}
                              style={{ borderBottom: "1px solid black" }}
                            >
                              <div>
                                <p style={{ fontSize: "0.8rem" }}>
                                  Data: <strong>{leitura.data}</strong>
                                </p>
                                <p style={{ fontSize: "0.8rem" }}>
                                  Abastecido:{" "}
                                  <strong>
                                    {leitura.qntdAbastecimento != 0
                                      ? `${formatarNumero(
                                          Number(leitura.qntdAbastecimento)
                                        )} L`
                                      : "-"}
                                  </strong>
                                </p>
                                <p style={{ fontSize: "0.8rem" }}>
                                  Totalizador:{" "}
                                  <strong>
                                    {leitura.totalizador != 0
                                      ? `${formatarNumero(
                                          Number(leitura.totalizador)
                                        )} L`
                                      : "-"}
                                  </strong>
                                </p>
                                <p style={{ fontSize: "0.9rem" }}>
                                  Motorista:{" "}
                                  <strong>
                                    {!leitura.motorista
                                      ? "-"
                                      : leitura.motorista.nome}
                                  </strong>
                                </p>{" "}
                                <p style={{ fontSize: "0.9rem" }}>
                                  Frentista:{" "}
                                  <strong>
                                    {!leitura.frentista
                                      ? "-"
                                      : leitura.frentista.nome}
                                  </strong>
                                </p>{" "}
                                <p style={{ fontSize: "0.9rem" }}>
                                  Placa:{" "}
                                  <strong>
                                    {leitura.placa != 0 || !leitura.placa
                                      ? `${leitura.placa?.placa}`
                                      : "-"}{" "}
                                  </strong>
                                </p>{" "}
                                <p style={{ fontSize: "0.9rem" }}>
                                Modelo:{" "}
                                  <strong>
                                    {leitura.placa !== null
                                      ? `${leitura.placa.modelo}`
                                      : "-"}{" "}
                                  </strong>
                                </p>{" "}
                                <p style={{ fontSize: "0.9rem" }}>
                                  Odômetro:{" "}
                                  <strong>
                                    {leitura.placa !== null && leitura.odometro != 0
                                      ? `${Number(leitura.odometro)} km`
                                      : "-"}{" "}
                                  </strong>
                                </p>{" "}
                              </div>
                            </li>
                          ))}
                        </>
                      </ul>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
