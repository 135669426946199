import React, { useContext, useEffect, useState } from "react";
import "./Sensores.css"; // Import the component-specific styling
import { SensoresInfo } from "./SensoresInfo";
import { Header } from "../Header/Header";
import { useForm } from "react-hook-form";
import { api } from "../../services/api";
import { toast } from "react-toastify";
import { SensoresContext } from "../../providers/SensoresContext";
import { MdOutlineSensors, MdSearch } from "react-icons/md";
import { SensoresFormSchema } from "./SensoresFormSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Footer } from "../Footer/Footer";
import { useNavigate } from "react-router-dom";

const Sensores = () => {
  const [error, setError] = useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isAddingSensor, setIsAddingSensor] = useState(false);
  const [sensoresComModais, setSensoresComModais] = useState({});
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(SensoresFormSchema),
  });
  const [sensorDeleteId, setSensorDeleteId] = useState("");

  const { sensores, setSensores } = useContext(SensoresContext);
  const [searchSensor, setSearchSensor] = useState("");
  const [filteredSensores, setFilteredSensores] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();



  useEffect(() => {
    const getAllSensores = async () => {
      const token = JSON.parse(localStorage.getItem("token"));
      setLoading(true);
      try {
        const { data } = await api.get("/modulos", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const sensoresRevertidos = data.reverse();
        setSensores(sensoresRevertidos);
     
      } catch (error) {
        if (error.response && error.response.status === 403) {
          // Se o erro for 403 (Forbidden)
        
          
          navigate('/')
         
        } else {
          // Se for um erro diferente de 403, faça o log do erro
          console.log(error);
        } } finally {
        setLoading(false);
      }
    };

    getAllSensores();
  }, []);

  const confirmRemoveSensor = (sensorId) => {
    setSensoresComModais({ ...sensoresComModais, [sensorId]: true });

    setSensorDeleteId(sensorId);
    setShowDeleteConfirmation(sensorId);
  };

  const deleteSensor = async () => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      await api.delete(`/modulos/${sensorDeleteId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const newSensoresList = sensores.filter(
        (sensor) => sensor.id !== sensorDeleteId
      );
      setSensores(newSensoresList);
      toast.success("Módulo excluído com sucesso");
      setShowDeleteConfirmation(false);
    } catch (error) {
      console.log(sensorDeleteId);
      console.log(error);
      toast.error("Ops alguma coisa deu errado!");
    }
  };

  const addSensor = async (formData) => {
    const { nome, id } = formData;
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const { data } = await api.post(
        "/modulos",
        {
          id,
          nome,
      
          clientId: null,
          tanqueId: null,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setIsAddingSensor(false);
      setSensores([...sensores, data]);
      toast.success("Módulo adicionado com sucesso!");
    } catch (error) {
      console.log(error);
      toast.error("Ops alguma coisa deu errado!");
    }
  };

  const submit = async (formData) => {
    await addSensor(formData);
    reset({ nome: "", marca: "" });
  };

  const submitSearch = (data) => {
    reset({ searchSensor: "" });
    setSearchSensor(searchSensor);
  };

  useEffect(() => {
    const filteredSensoresList = sensores.filter(
      (sensor) =>
        sensor.nome.toLowerCase().includes(searchSensor.toLowerCase()) ||
        sensor.id == searchSensor ||  sensor.cliente?.nome.toLowerCase().includes(searchSensor.toLowerCase())
    );

    setFilteredSensores(filteredSensoresList);
  }, [searchSensor, sensores]);

  const sensoresList = searchSensor ? filteredSensores : sensores;

  return (
    <>
      <Header />
      <div className="flexBox">
        <div className={isAddingSensor ? "containerBox" : "sensorContainerBox"}>
          {isAddingSensor ? (
            <div>
              <h2>Módulo</h2>
              <form onSubmit={handleSubmit(submit)}>
                <div className="input-container">
                  <input
                    type="text"
                    placeholder="ID do módulo"
                    {...register("id")}
                  />
                  {errors.id ? <p>{errors.id.message}</p> : null}
                  <input
                    type="text"
                    placeholder="Nome do módulo"
                    {...register("nome")}
                  />
                  {errors.nome ? <p>{errors.nome.message}</p> : null}
                  <button type="submit" className="custom-button button">
                    Cadastrar módulo
                  </button>
                  <button
                    className="cancel-button-cliente"
                    onClick={() => setIsAddingSensor(false)}
                  >
                    Cancelar
                  </button>
                </div>
                {error && <p className="error-message">{error}</p>}
              </form>
            </div>
          ) : (
            <div className="botaoBox">
              <div className="titlePag">
                <MdOutlineSensors size={40} />
                <h2>Módulos</h2>
              </div>
              <div className="searchBoxFlex">
                <button
                  className="botao-add"
                  onClick={() => setIsAddingSensor(true)}
                >
                  Adicionar módulo
                </button>
                <form
                  onSubmit={handleSubmit(submitSearch)}
                  className="formSearch"
                >
                  <input
                    type="text"
                    {...register("searchSensor")}
                    value={searchSensor}
                    placeholder="Digite id, nome do módulo ou cliente"
                    onChange={(e) => setSearchSensor(e.target.value)}
                  />
                  <button type="submit" className="btnSearch">
                    <MdSearch size={21} />
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
  
        {loading ? (
          <>
            <div className=" container ">
              <p
                style={{
                  position: "relative",
                  justifyContent: "center",
                  color: "white",
                  margin: "30px",
                  padding: "34px",
                }}
              >
                Carregando...
              </p>
            </div>
          </>
        ) : (
          <>
            <div className=" container ">
              {sensoresList?.length === 0 ? (
                <p
                  style={{
                    position: "relative",
                    justifyContent: "center",
                    color: "white",
                    margin: "30px",
                    padding: "34px",
                  }}
                >
                  Nenhum resultado encontrado.
                </p>
              ) : (
                <ul className="listas">
                  {sensoresList.map((sensor, index) => (
                    <SensoresInfo
                      key={index}
                      sensor={sensor}
                      deleteSensor={deleteSensor}
                      confirmRemoveSensor={confirmRemoveSensor}
                      sensoresComModais={sensoresComModais}
                      setSensoresComModais={setSensoresComModais}
                    />
                  ))}
                </ul>
              )}
            </div>
          </>
        )}
      </div>
  
    </>
  );
};

export default Sensores;
