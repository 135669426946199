import React, { useContext, useState } from "react";
import { AuthContext } from "../AuthContext";
import "./Login.css";
import { api } from "../../services/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BannerLogin from "../../assets/BannerLogin.png";

const Login = () => {

  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loginExists, setLoginExists] = useState(true);
  const navigate = useNavigate();

  const {
    username,
    password,
    setUsername,
    setPassword,
    setUserRole,
 
    setAuthenticated,
    setUser,

    setLoading,
  } = useContext(AuthContext);

  
  const handleLogin = async (e) => {
    e.preventDefault();
    if (username.trim() === "") {
      setUsernameError(true);
      return;
    } else {
      setUsernameError(false);
    }

    e.preventDefault();
    if (password.trim() === "") {
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }



    getRole();


    setLoginExists(false);
  };




  const getRole = async () => {
    try {
      setLoading(true);
      const response = await api.post(
        "/auth",
        {
          login: username,
          senha: password,
        },
        { headers: { "Content-Type": "application/json" } }
      );
      setUser(response.data);
      localStorage.setItem("user", JSON.stringify(response.data));
      localStorage.setItem("token", JSON.stringify(response.data.token));
      localStorage.setItem("admin", JSON.stringify(response.data.admin));
      localStorage.setItem("nomeCliente", JSON.stringify(response.data.nomeCliente));
      localStorage.setItem("ID", JSON.stringify(response.data.id));
  
      setUserRole(response.data.admin);
      setAuthenticated(true);
      setPassword(password);
      setUsername(username);

const role = JSON.parse(localStorage.getItem("admin"))
      
      if (role) {
        navigate("/admin/dashboard");
      } else if (role === false) {
        navigate("/user/dashboard");
      }
 

    } catch (error) {
      console.log(error);
      toast.error("Usuário ou senha incorretos");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="containerLogin">
      <div className="loginImg">
        <img className="img" src={BannerLogin} alt="banner com celular" />
      </div>

      <form className="loginBox" onSubmit={handleLogin}>
        <div className="form">
          <h2>Login</h2>
          <p>Bem-vindo!</p>

          <input
            type="text"
            placeholder="Usuário"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className={"input"}
          />
          {usernameError && (
            <p className="error-message">Usuário é obrigatório</p>
          )}

          <input
            type="password"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="input"
          />
         {passwordError && (
            <p className="error-message">Senha é obrigatório</p>
          )}

          <button className="loginButton" type="submit">
            Entrar
          </button>
        </div>
      </form>

      {!loginExists ? (
        <div className="non-user">Usuário não cadastrado</div>
      ) : null}
    </div>
  );
};

export default Login;
