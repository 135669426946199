import { React, useContext } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { AuthContext } from "../AuthContext";


export const MenuMobile = () => {
    const { setUsername, setPassword, setUser, setUserRole, setIsMenuHamburguerVisible } =
    useContext(AuthContext);
  const handleLogout = () => {
    setUser({});
    setUsername("");
    setPassword("");
    setUserRole("");
    localStorage.clear();
  };
  
  return (
    <nav className="navBarHamburguer">
            <div className="navMobile">
                <button  className="closeButton" onClick={() => setIsMenuHamburguerVisible(false)}>X</button>
              <Link className="link" to="/admin/dashboard" onClick={() => setIsMenuHamburguerVisible(false)}>
                Painel
              </Link>
              <Link className="link" to="/clientes" onClick={() => setIsMenuHamburguerVisible(false)} >
               Clientes
              </Link>
              <Link className="link" to="/modulos" onClick={() => setIsMenuHamburguerVisible(false)}>
                Módulos
              </Link>
              <Link className="link" to="/tanques" onClick={() => setIsMenuHamburguerVisible(false)}>
                Tanques
              </Link>
              <Link className="link" to="/" onClick={handleLogout} >
                Sair
              </Link>
            </div>
        
          </nav>
        
  );
};
