import React, { createContext, useState, useEffect } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState(null);
  const [userID, setUserID] = useState("");
  const [loading, setLoading] = useState(false);
  const [isMenuHamburguerVisible, setIsMenuHamburguerVisible] = useState(false);
  const [expirou, setExpirou] = useState(false);

  // useEffect(() => {
  //   if (password) {
  //     localStorage.setItem("senha", password)
  //   }
  //   setPassword(localStorage.getItem("senha"));
  // }, [password]);


  useEffect(() => {
    const loggedInToken = JSON.parse(localStorage.getItem("token"));
    const loggedInUser = JSON.parse(localStorage.getItem("user"));
    const loggedInAdmin = JSON.parse(localStorage.getItem("admin"));
    if (loggedInToken) {
    
      setUserRole(loggedInAdmin);
      setUser(loggedInUser);
    }
  }, []);



  return (
    <AuthContext.Provider
      value={{
        userID,
        setUserID,
        password,
        setPassword,
        username,
        setUsername,
        authenticated,
        userRole,
        setAuthenticated,
        setUserRole,
        user,
        setUser,
        loading,
        setLoading,
        setIsMenuHamburguerVisible,
        isMenuHamburguerVisible,
        expirou, setExpirou
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

