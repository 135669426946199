import { Link } from "react-router-dom";
import { GiWaterTank } from "react-icons/gi";
export const TanquesInfo = ({
  deleteTanque,
  confirmRemoveTanque,
  tanque,
  tanquesComModais,
  setTanquesComModais,
}) => {
  return (
    <>
      <li className="itemCardAdmin">
        <div className="client">
          <GiWaterTank className="tank" size={55} />
          <p className="">Nome: <strong>{tanque.nome}</strong> </p>
        </div>

        {tanque.tipoTanque == "tanque_cilindrico_em_pe" && (
          <>
            <p><strong>Cilíndrico Vertical</strong></p>
            <div>
              <p className="">Altura: <strong>{tanque.comprimento} cm</strong> </p>
              <p className="">Raio: <strong>{tanque.raio} cm</strong></p>
              <p className="">Correção: <strong>{tanque.correcao} mm</strong></p>
            </div>

            <div className="clienteBotoes">
              <Link className="botao" to={`/tanques-cilindrico-em-pe/${tanque.id}`}>
                Editar
              </Link>
              <button
                className="botao-remove"
                onClick={() => confirmRemoveTanque(tanque.id)}
              >
                Remover
              </button>
            </div>
          </>
        )}

{tanque.tipoTanque == "tanque_cilindrico_deitado" && (
          <>
            <p><strong>Cilíndrico Horizontal</strong></p>
            <div>
              <p className="">Comprimento: <strong>{tanque.comprimento} cm</strong> </p>
              <p className="">Raio: <strong>{tanque.raio} cm</strong></p>
              <p className="">Correção: <strong>{tanque.correcao} mm</strong></p>
            </div>

            <div className="clienteBotoes">
              <Link className="botao" to={`/tanques-cilindrico-deitado/${tanque.id}`}>
                Editar
              </Link>
              <button
                className="botao-remove"
                onClick={() => confirmRemoveTanque(tanque.id)}
              >
                Remover
              </button>
            </div>
          </>
        )}

{tanque.tipoTanque == "tanque_retangular" && (
          <>
          <div>
            <p><strong>Retangular</strong></p>
          </div>
            <div>
              <p className="">Comprimento: <strong>{tanque.comprimento} cm</strong> </p>
              <p className="">Altura: <strong>{tanque.altura} cm</strong></p>
              <p className="">Largura: <strong>{tanque.largura} cm</strong></p>
              <p className="">Correção: <strong>{tanque.correcao} mm</strong></p>
            </div>
            <div className="clienteBotoes">
            
         
              {tanque.tipoTanque == "tanque_retangular" && <div className="clienteBotoes">
              <Link className="botao" to={`/tanques-retangular/${tanque.id}`}>
                Editar
              </Link></div>}
              <button
                className="botao-remove"
                onClick={() => confirmRemoveTanque(tanque.id)}
              >
                Remover
              </button>
              </div>
            </>
          
        )}

        {tanquesComModais[tanque.id] ? (
          <div className="confirmation-modal">
            <div className="confirmation-box">
              <p>Tem certeza de que deseja apagar o tanque {tanque.nome}?</p>
              <div className="confirmation-buttons">
                <button
                  className="confirm-button"
                  onClick={() => deleteTanque(tanque.id)}
                >
                  Confirmar
                </button>
                <button
                  className="cancel-button"
                  onClick={() => {
                    setTanquesComModais(false);
                  }}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </li>
    </>
  );
};
